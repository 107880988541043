<template>
  <div class="roofinders">
    <template v-if="!noMenu">
      <MenuMobile :theme="theme" :theme-color="themeColor" v-if="isSm" />
      <Menu :theme="theme" :theme-color="themeColor" v-else />
    </template>
    <transition name="fade" mode="out-in">
      <router-view class="roofinders-content"/>
    </transition>
    <Footer :theme="theme" :theme-color="themeColor" v-if="!noFooter"/>
  </div>
</template>

<script>
import Menu from '@/components/menu/Menu'
import MenuMobile from '@/components/menu/MenuMobile'
import Footer from '@/components/Footer'
import { mapGetters } from 'vuex'

export default {
  name: 'RooFinders',
  components: {
    Menu,
    MenuMobile,
    Footer,
  },
  computed: {
    ...mapGetters('windowStore', ['isSm']),
    theme() {
      return this.$route.meta.layout ? this.$route.meta.layout : 'blue'
    },
    themeColor() {
      return this.$route.meta.theme ? this.$route.meta.theme : 'blue'
    },
    noFooter() {
      return this.$route.meta.noFooter
    },
    noMenu() {
      return this.$route.meta.noMenu
    },
  },
  created() {
    this.$store.dispatch('countryStore/getCountries')
  },
  mounted() {
    this.handleOverflow()
  },
  watch: {
    $route: {
      handler() {
        this.handleOverflow()
      }
    }
  },
  methods: {
    handleOverflow() {
      const content = document.querySelector('.roofinders-content')

      if (content) {
        if (!this.$route.path.includes('admin')) {
          content.classList.add('overflow-hidden')
        } else {
          content.classList.remove('overflow-hidden')
        }
      }
    }
  }
}
</script>

<style lang="scss">
.roofinders {
  .roofinders-content {
    min-height: 100vh;

    &.overflow-hidden {
      overflow: hidden;
    }
  }
}
</style>

