const state = {
  reservation: null,
}

const mutations = {
  setReservation(state, reservation) {
    state.reservation = reservation
  }
}

export const contractStore = {
  namespaced: true,
  state,
  mutations,
}