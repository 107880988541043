import { getAdminItems, postAdminData, putAdminData, deleteAdminData } from '@/utils/network'

const getOwners = async () => await getAdminItems(`/owners`)

const createOwner = async owner => await postAdminData(`/owners`, owner)

const updateOwner = async owner => await putAdminData(`/owners/${owner.id}`, owner)

const deleteOwner = async id => await deleteAdminData(`/owners/${id}`)

export const ownerService = {
  getOwners,
  createOwner,
  updateOwner,
  deleteOwner,
}