import { getItem, getItems } from '@/utils/network'

const getBuilding = async id => await getItem(`/buildings/${id}`)

const getBuildingByName = async name => await getItem(`/buildings/${name}`)

const getBuildingByNameFilteredRooms = async (name,startDate, endDate) => {
  let endpoint = `/buildings/${name}/search`
  if(startDate !== null || endDate !== null){
    endpoint = `${endpoint}?`
  }
  endpoint = startDate ? `${endpoint}startDate=${startDate}` : endpoint
  if(startDate!== null && endDate !== null){
    endpoint=`${endpoint}&`
  }
  endpoint = endDate ? `${endpoint}endDate=${endDate}` : endpoint

  return await getItems(endpoint)
}

const getBuildings = async () => await getItems(`/buildings`)

export const buildingService = {
  getBuilding,
  getBuildings,
  getBuildingByName,
  getBuildingByNameFilteredRooms
}
