const state = {
  service: null,
}

const mutations = {
  setService(state, service) {
    state.service = service
  }
}

export const adminServiceStore = {
  namespaced: true,
  state,
  mutations,
}