import { postAdminData, getAdminItems, putAdminData, deleteAdminData } from '@/utils/network'

const getBuildingCharacteristics = async () => await getAdminItems(`/characteristics`)

const createCharacteristic = async buildingCharacteristic => await postAdminData(`/characteristics`, buildingCharacteristic)

const editCharacteristic = async characteristicForm => await putAdminData(`/characteristics/${characteristicForm.get('id')}`, characteristicForm)

const deleteCharacteristic = async characteristicId => await deleteAdminData(`/characteristics/${characteristicId}`)

export const buildingCharacteristicService = {
  getBuildingCharacteristics,
  createCharacteristic,
  editCharacteristic,
  deleteCharacteristic
}
