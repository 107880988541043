import { postAdminData, patchAdminData, getAdminItems, deleteAdminData, putAdminData } from '@/utils/network'

const getRooms = async () => await getAdminItems(`/rooms`)

const addRoom = async (buildingId, room) => await postAdminData(`/buildings/${buildingId}/addroom`, room)

const editRoom = async room => await putAdminData(`/room/${room.id}`, room)

const deleteRoom = async roomId => await deleteAdminData(`/room/${roomId}`)

const restoreRoom = async roomId => await patchAdminData(`/room/${roomId}`)

const batchPricing = async (roomTypePrice) => await patchAdminData('room/batch-pricing', roomTypePrice)

export const roomService = {
  getRooms,
  addRoom,
  editRoom,
  deleteRoom,
  restoreRoom,
  batchPricing
}
