import { getItems, postAdminData, putAdminData } from '@/utils/network'

const getBuildingTypes = async () => await getItems(`/residencetypes`)

const addBuildingType = async buildingType => await postAdminData(`/residencetypes`, buildingType)

const editBuildingType = async buildingType => await putAdminData(`/residencetype/${buildingType.id}`, buildingType)

export const buildingTypeService = {
  getBuildingTypes,
  addBuildingType,
  editBuildingType,
}