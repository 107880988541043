import { getItem, postData, getAuthItems, putAuthData, patchData, patchAuthData } from '@/utils/network'


const validateTokenComplete = async (email, token) => await getItem(`/users/confirm?email=${email}&token=${token}`)

const validateTokenForgot = async (email, token) => await getItem(`/resetpassword/confirm?email=${email}&token=${token}`)

const addPassword = async (userId, auth) => await patchData(`/addpassword/${userId}`, auth)

const createUser = async (user) => await postData(`/user/create`, user)

const getUserById = async (userId) => await getItem(`/user/${userId}`)

const updateUser = async (userId, user) => {
  const { data: updatedUser } = await putAuthData(`/updateuser/${userId}`, user)

  return updatedUser
}

const updateGuest = async (reservationId, userId, guest) => {
  const { data: updatedUser } = await putAuthData(`/reservation/${reservationId}/guest/${guest.id}/user/${userId}`, guest)

  return updatedUser
}

const updatePassword = async (userId, user) => await patchAuthData(`/changepassword/${userId}`, user)

const sendEmail = async email => await postData(`/resetpassword`, email)

const fetchReservationList = async (userId, form) => await getAuthItems(`/reservations/user/${userId}`, form)

const getReservationById = async (userId,reservationId) => await getAuthItems(`/reservations/user/${userId}/${reservationId}`)

export const userService = {
  validateTokenComplete,
  validateTokenForgot,
  addPassword,
  updateUser,
  updateGuest,
  updatePassword,
  sendEmail,
  fetchReservationList,
  createUser,
  getUserById,
  getReservationById
}
