// Homepage
const Homepage = () => import('@/pages')

// Auth
const CompleteRegistration = () => import('@/pages/auth/complete-registration')
const Login = () => import('@/pages/auth/login')
const SignUp = () => import('@/pages/auth/sign-up')
const ForgotPassword = () => import('@/pages/auth/forgot-password')
const SendEmail = () => import('@/pages/auth/forgot-password/send-email')
const ResetPassword = () => import('@/pages/auth/forgot-password/reset-password')

// User Profile
const ProfilePage = () => import('@/pages/profile')
const ProfileYourInfo = () => import('@/pages/profile/your-info')
const ProfileYourRoof = () => import('@/pages/profile/your-roof')
const ProfileChangePassword = () => import('@/pages/profile/change-password')

const YourRoofDetail = () => import('@/pages/profile/your-roof/_id')
const YourRoofPayment = () => import('@/pages/profile/your-roof/_id/payment')
const YourRoofPaymentCreditCardDepositAndMonthly = () => import('@/pages/profile/your-roof/_id/credit-card-payments/payment-credit-card-deposit-and-monthly')
const YourRoofPaymentCreditCardDepositBTMonthly = () => import('@/pages/profile/your-roof/_id/credit-card-payments/payment-credit-card-deposit-bt-monthly')
const YourRoofPaymentCreditCardDepositAndMonthlyManual = () => import('@/pages/profile/your-roof/_id/credit-card-payments/payment-credit-card-deposit-and-monthly-manual')
const YourRoofGuests = () => import('@/pages/profile/your-roof/_id/guests')
const YourRoofChangeCard = () => import('@/pages/profile/your-roof/_id/change-card')

const YourRoofPayMonthlyRent = () => import('@/pages/profile/your-roof/_id/pay-monthly-rent')

const YourRoofPayMonthlyRentOptions = () => import('@/pages/profile/your-roof/_id/pay-monthly-rent-options')
const YourRoofPayMonthlyRentAutomatic = () => import('@/pages/profile/your-roof/_id/pay-monthly-rent-automatic')

// Apartments
const ApartmentsPage = () => import('@/pages/apartments')
const ApartmentPage = () => import('@/pages/apartments/_name')

// Buildings
const BuildingsPage = () => import('@/pages/buildings')
const BuildingPage = () => import('@/pages/buildings/_name')

// BookingRequest
const BookingRequestPage = () => import('@/pages/booking-request')

// Search Page
const SearchPage = () => import('@/pages/search')

// Not Found
const NotFoundPage = () => import('@/pages/404')

//Footer links

  //Privacy policy
const PrivacyPolicy = () => import('@/pages/footer_links/privacy_policy')

  //Cookies policy
const CookiesPolicy = () => import('@/pages/footer_links/cookies_policy')


const routes = [
  {
    path: '/',
    name: 'homepage',
    component: Homepage,
    meta: {
      layout: 'transparent',
      theme: 'blue',
    }
  },
  {
    path: '/auth/complete-registration',
    name: 'complete-registration',
    component: CompleteRegistration,
  },
  {
    path: '/auth/login',
    name: 'login',
    component: Login,
  },
  {
    path: '/auth/sign-up',
    name: 'sign-up',
    component: SignUp,
  },
  {
    path: '/auth/forgot-password',
    name: 'forgot-password',
    component: ForgotPassword,
    children: [
      {
        path: 'send-email',
        name: 'send-email',
        component: SendEmail,
      },
      {
        path: 'reset-password',
        name: 'reset-password',
        component: ResetPassword,
      }
    ]
  },
  {
    path: '/apartments',
    name: 'apartments-page',
    component: ApartmentsPage,
    meta: {
      layout: 'transparent',
      theme: 'green',
    },
  },
  {
    path: '/apartments/:name',
    name: 'apartment-page',
    component: ApartmentPage,
    meta: {
      layout: 'transparent',
      theme: 'green',
    }
  },
  {
    path: '/buildings',
    name: 'buildings-page',
    component: BuildingsPage,
    meta: {
      layout: 'transparent',
      theme: 'orange',
    }
  },
  {
    path: '/buildings/:name',
    name: 'building-page',
    component: BuildingPage,
    meta: {
      layout: 'transparent',
      theme: 'orange',
    }
  },
  {
    path: '/profile',
    name: 'profile',
    component: ProfilePage,
    meta: {
      layout: 'blue',
      theme: 'blue',
    },
    children: [
      {
        path: 'your-info',
        name: 'your-info',
        component: ProfileYourInfo,
      },
      {
        path: 'your-roof',
        name: 'your-roof',
        component: ProfileYourRoof,
      },
      {
        path: 'change-password',
        name: 'change-password',
        component: ProfileChangePassword,
      },
      {
        path: 'your-roof/:id',
        name: 'your-roof-detail',
        component: YourRoofDetail,
      },
      {
        path: 'your-roof/:id/guests',
        name: 'your-roof-guests',
        component: YourRoofGuests,
      },
      {
        path: '/profile/your-roof/:id/payment',
        name: 'payment',
        component: YourRoofPayment,
      },
      {
        path: '/profile/your-roof/:id/payment/credit-card-deposit-and-monthly',
        name: 'payment-credit-card-deposit-and-monthly',
        component: YourRoofPaymentCreditCardDepositAndMonthly,
      },
      {
        path: '/profile/your-roof/:id/payment/credit-card-deposit-bt-monthly',
        name: 'payment-credit-card-deposit-bt-monthly',
        component: YourRoofPaymentCreditCardDepositBTMonthly,
      },
      {
        path: '/profile/your-roof/:id/payment/credit-card-deposit-and-monthly-manual',
        name: 'payment-credit-card-deposit-and-monthly-manual',
        component: YourRoofPaymentCreditCardDepositAndMonthlyManual,
      },
      {
        path: '/profile/your-roof/:id/change-card',
        name: 'change-card',
        component: YourRoofChangeCard,
      },
      {
        path: '/profile/your-roof/:id/pay-monthly-rent',
        name: 'pay-monthly-rent',
        component: YourRoofPayMonthlyRent,
      },
      {
        path: '/profile/your-roof/:id/pay-monthly-rent-options',
        name: 'pay-monthly-rent-options',
        component: YourRoofPayMonthlyRentOptions,
      },
      {
        path: '/profile/your-roof/:id/pay-monthly-rent-automatic',
        name: 'pay-monthly-rent-automatic',
        component: YourRoofPayMonthlyRentAutomatic,
      },
    ],
  },
  {
    path: '/booking-request',
    name: 'booking-request-page',
    component: BookingRequestPage,
    meta: {
      layout: 'transparent',
      theme: 'orange',
      noFooter: true,
      noMenu: true,
    },
  },
  {
    path: '/search',
    name: 'search-page',
    component: SearchPage,
    meta: {
      layout: 'transparent',
      theme: 'blue',
    }
  },
  {
    path: '*',
    name: 'not-found',
    component: NotFoundPage,
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy-page',
    component: PrivacyPolicy,
    meta: {
      layout: 'transparent',
      theme: 'blue',
    }
  },
  {
    path: '/cookies-policy',
    name: 'cookies-policy-page',
    component: CookiesPolicy,
    meta: {
      layout: 'transparent',
      theme: 'blue',
    }
  }
]

export default routes
