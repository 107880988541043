<template>
  <div class="footer" :style="{ backgroundColor: backgroundMenu }">
    <b-container>
      <b-row class="justify-content-between">
        <b-col cols="12" md="auto" class="d-flex flex-column align-items-center d-md-block">
          <router-link to="/">
            <img src="@/assets/images/logo_footer.png"/>
          </router-link>
          <div class="all-rights-reserved text-center text-md-left">© {{currentYear}} RooFinders Srl. {{$t('All rights reserved')}}.</div>
        </b-col>
        <b-col cols="12" md="auto" class="d-flex flex-column justify-content-around">
          <div class="links">
            <a class="external-link" href="mailto:ciao@roofinders.com" target="_blank">ciao@roofinders.com</a>
            <div class="social d-flex justify-content-center d-md-inline">
              <a class="external-link ml-3" href="https://www.instagram.com/roofindersmilan/" target="_blank">
                <FontAwesomeIcon :icon="['fab', 'instagram']"/>
              </a>
              <a class="external-link ml-3" href="https://www.facebook.com/RooFindersMilan/" target="_blank">
                <FontAwesomeIcon :icon="['fab', 'facebook']"/>
              </a>
              <a class="external-link ml-3" href="https://wa.me/393518897372" target="_blank">
                <FontAwesomeIcon :icon="['fab', 'whatsapp']"/>
              </a>
            </div>
          </div>
          <div class="pages">
            <div class="d-block text-center text-md-right d-md-inline-block mt-2 mt-md-0" v-for="(page, index) in pages" :key="index">
              <router-link class="rf-page-link" :to="page.to">{{$t(page.label)}}</router-link>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'Footer',
  props: {
    theme: String,
    themeColor: String,
  },
  data() {
    return {
      backgroundMenu: null,
      pages: [
        { label: 'Privacy', to: '/privacy-policy' },
        { label: 'CookiesPolicy', to: '/cookies-policy' },
      ]
    }
  },
  computed: {
    currentYear() {
      return moment().format('YYYY')
    },
  },
  mounted() {
    this.setBackground()
  },
  methods: {
    setBackground() {
      this.backgroundMenu = this.themeColor === 'green' ? '#67AEA6' :
                            this.themeColor === 'blue' ? '#24425B' :
                            this.themeColor === 'orange' ? '#E26F5A'
                            : '#24425B'
    },
  },
  watch: {
    $route: {
      deep: true,
      handler() {
        this.setBackground()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.footer {
  position: relative;
  z-index: 30;
  padding: 20px 0;
  border-top: .1px solid $white;

  .all-rights-reserved {
    @include font-style($hind, 'light', $font-14);
    color: $white;
    margin-top: 20px;
  }
  .links {
    text-align: right;

    .external-link {
      font-size: $font-14;
      color: $white;
      display: block;
      text-align: center;
      margin: 10px 0;

      .social-icon {
        color: $white;
      }
    }
  }
  .pages {
    .rf-page-link {
      font-size: $font-14;
      color: $white;
      margin-left: 10px;
    }
  }
}

@media (min-width: map-get($grid-breakpoints, 'md')) {
  .footer {
    .links {
      .external-link {
        display: inline-block;
      }
    }
  }
}
</style>

