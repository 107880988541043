import Vue from 'vue'
import { router } from '@/router/router'
import { resetStores } from '@/stores/reset-stores'
import { http, httpAuth, httpAdmin } from '@/helpers'
import { isAdmin } from '@/helpers/functions'
import { getItem, postData } from '@/utils/network'
import i18n from '@/plugins/i18n'
import { store } from '@/stores'
import accessToken from '@/helpers/access-token'

const login = async (user) => {
  try {
    const { data: payload } = await postData('/authenticate', user)
    const { user: loggedUser , token } = payload

    localStorage.setItem('X-Auth', token)

    if (isAdmin(loggedUser.roles)) {
      httpAdmin.defaults.headers['X-Auth'] = token
      delete httpAuth.defaults.headers['X-Auth']
    } else {
      httpAuth.defaults.headers['X-Auth'] = token
      delete httpAdmin.defaults.headers['X-Auth']
    }

    store.commit('userStore/setUser', loggedUser)

    return loggedUser
  } catch (error) {
    const message = i18n.t(`Username or password doesn't match`)
    Vue.toasted.error(message)
    return false
  }
}

const oauthLogin = async (user) => {
  try {
    const { data: payload } = await postData('/oauth', user)
    const { user: loggedUser , token } = payload

    localStorage.setItem('X-Auth', token)

    if (isAdmin(loggedUser.roles)) {
      httpAdmin.defaults.headers['X-Auth'] = token
      delete httpAuth.defaults.headers['X-Auth']
    } else {
      httpAuth.defaults.headers['X-Auth'] = token
      delete httpAdmin.defaults.headers['X-Auth']
    }

    store.commit('userStore/setUser', loggedUser)

    return loggedUser
  } catch (error) {
    const message = i18n.t(`Username or password doesn't match`)
    Vue.toasted.error(message)
    return false
  }
}

const logout = async () => {
  localStorage.removeItem('X-Auth')

  delete httpAdmin.defaults.headers['X-Auth']
  delete httpAuth.defaults.headers['X-Auth']

  await resetStores()
  store.commit('userStore/setUser', null)

  if (router.history.current.name !== 'homepage') {
    router.push({ name: 'homepage' })
  }
}

const fetchUser = async () => {
  http.defaults.headers['X-Auth'] = accessToken
  const { data: user } = await getItem('/fetch-user')

  store.commit('userStore/setUser', user)
}

export const authService = {
  fetchUser,
  login,
  logout,
  oauthLogin
}
