import axios from 'axios'
import i18n from '@/plugins/i18n'

export const http = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  headers: {
    'Content-type': 'application/json',
    'Accept': 'application/json',
    'lang': i18n.locale,
  }
})