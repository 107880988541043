<template>
  <div class="rf-form">
    <ValidationObserver ref="validator">
      <form @submit.prevent="submit" novalidate>
        <div class="form-row" v-for="(field, index) in fields" :key="index">
          <template v-if="field.toValidate">
            <RFValidation class="validation" :name="field.name" :rules="concatRules(field.rules)">
              <RFInput v-if="isInput(field.type)" :type="field.type" :min="field.min" :max="field.max" :label="field.label" v-model="field.value" :rounded="field.rounded" :color="field.color" :labelColor="field.labelColor"/>
              <RFRadio v-if="field.type === 'radio'" :label="field.label" :value="field.value" @input="field.value = $event" :list="field.list"/>
              <RFTextArea v-if="field.type === 'textarea'" :label="field.label" v-model="field.value" :color="field.color"/>
              <RFDatePicker v-if="field.type === 'date'" :label="field.label" v-model="field.value" :color="field.color"/>
            </RFValidation>
          </template>
          <template v-else>
            <RFInput v-if="isInput(field.type)" :label="field.label" v-model="field.value" :rounded="field.rounded" :color="field.color" :labelColor="field.labelColor"/>
            <RFRadio v-if="field.type === 'radio'" :label="field.label" :name="field.name" :value="field.value" @input="field.value = $event" :list="field.list"/>
            <RFTextArea v-if="field.type === 'textarea'" :label="field.label" v-model="field.value" :color="field.color"/>
            <RFDatePicker v-if="field.type === 'date'" :label="field.label" v-model="field.value" :color="field.color"/>
            <RFSelect v-if="field.type === 'select'" v-model="field.value" :list="field.list"/>
          </template>
          <template v-if="field.type === 'button'">
            <RFButton class="mt-3" :full-width="field.fullWidth"  v-if="!loading" :label="field.label" :background="field.background" :borderColor="field.borderColor"/>
            <RFLoader class="mt-3" :size="30" color="#24425B" v-if="loading"/>
          </template>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import RFInput from '@/components/forms/RFInput'
import RFButton from '@/components/forms/RFButton'
import RFRadio from '@/components/forms/RFRadio'
import RFTextArea from '@/components/forms/RFTextArea'
import RFDatePicker from '@/components/forms/RFDatePicker'
import RFSelect from '@/components/forms/RFSelect'
import RFLoader from '@/components/forms/RFLoader'
import RFValidation from '@/components/forms/RFValidation'

export default {
  components: {
    RFInput,
    RFButton,
    RFRadio,
    RFTextArea,
    RFDatePicker,
    RFSelect,
    RFLoader,
    RFValidation,
  },
  props: {
    fields: Array,
    loading: {
      type: Boolean,
      default: false,
    }
  },
  methods: {
    isInput(type) {
      return type !== 'radio' &&
              type !== 'checkbox' &&
              type !== 'textarea' &&
              type !== 'date' &&
              type !== 'button' &&
              type !== 'select'
    },
    async submit() {
      const isValid = await this.$refs.validator.validate()

      if (isValid) {
        this.$emit('submit')
      }
    },
    concatRules(rules) {
      let stringRules = ''

      rules.forEach((rule, index) => stringRules = `${stringRules}${rule}${index === rules.length - 1 ? '' : '|'}`)

      return stringRules
    }
  }
}
</script>

<style lang="scss">
.rf-form {
  .form-row {
    .validation {
      width: 100%;

      .validation-error {
        @include font-style($hind, 'medium', $font-12);
        color: $danger;
      }
    }
  }
}
</style>
