import Vue from 'vue'
import App from '@/App.vue'

// Bootstrap
import { BootstrapVue } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.min.css'

import Vuex from 'vuex'

import FlagIcon from 'vue-flag-icon'
import * as VueGoogleMaps from 'vue2-google-maps'

import i18n from '@/plugins/i18n'
import '@/plugins'

// Custom
import { router } from '@/router/router'
import { store } from '@/stores'
import '@/assets/css/style.css'
import '@/helpers/filters'
import '@/utils/viewport'
// import '@/utils/user'

Vue.config.productionTip = false

const plugins = [
  Vuex,
  BootstrapVue,
  FlagIcon,
]

plugins.forEach(plugin => Vue.use(plugin))

Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GMAP_KEY,
    libraries: 'places', // necessary for places input
    load: {
      region: 'IT',
      language: 'it',
    }
  },
})

new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
