import { accommodationTypeService } from '@/services/admin'

const state = {
  accommodationTypes: [],
  accommodationType: null,
}

const mutations = {
  setAccommodationTypes(state, accommodationTypes) {
    state.accommodationTypes = accommodationTypes
  },
  setAccommodationType(state, accommodationType) {
    state.accommodationType = accommodationType
  }
}

const actions = {
  async getAccommodationTypes({ commit }) {
    const accommodationTypes = await accommodationTypeService.getAccommodationTypes()
    commit('setAccommodationTypes', accommodationTypes)
  }
}

export const accommodationTypeStore = {
  namespaced: true,
  state,
  mutations,
  actions,
}