import { postAdminData, getAdminItems } from '@/utils/network'

const editContract = async contract => await postAdminData(`/contract`, contract)
const getContract = async () => await getAdminItems(`/contract`)
const downloadContract = async () => await getItems(`/contract/download`)

export const contractService = {
  editContract,
  getContract,
  downloadContract,
}