import { categoryService } from '@/services/admin'

const state = {
  categories: [],
  category: null,
}

const mutations = {
  setCategories(state, categories) {
    state.categories = categories
  },
  setCategory(state, category) {
    state.category = category
  }
}

const actions = {
  async getCategories({ commit }) {
    const categories = await categoryService.getCategories()
    commit('setCategories', categories)
  }
}

export const categoryStore = {
  namespaced: true,
  state,
  mutations,
  actions,
}
