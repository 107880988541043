const state = {
  apartment: null,
  filterData: null
}

const mutations = {
  setApartment(state, apartment) {
    state.apartment = apartment
  },
  setImages(state, images) {
    state.apartment.images = images
  },
  setInfos(state, infos) {
    state.apartment.infos = infos
  },
  setFilterData(state, filterData){
    state.filterData = filterData
  }
}

export const adminApartmentStore = {
  namespaced: true,
  state,
  mutations,
}
