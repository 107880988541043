<template>
  <div class="datepicker" :style="datePickerStyle">
    <div class="date" @click="!readonly ? openCalendar() : null">
      <RFInput :label="label" :color="color" :value="value | formatDate" readonly/>
    </div>
    <div class="calendar" v-if="isOpened">
      <div class="selected-year my-3">
        <FontAwesomeIcon v-if="showPrev()" class="cursor-pointer ml-3" :icon="['fas', 'chevron-left']" @click="subYear"/>
        <div class="ml-3" v-else>{{' '}}</div>
        <div class="year" @click="handleOpenCalendar">{{$t(selectedMonth)}} {{selectedYear}}</div>
        <FontAwesomeIcon v-if="showNext()" class="cursor-pointer mr-3" :icon="['fas', 'chevron-right']" @click="addYear"/>
        <div class="mr-3" v-else>{{' '}}</div>
      </div>

      <div class="calendar-years" v-if="openCalendarYears">
        <div v-for="(year, index) in selectedDecade" :key="`year-${index}`">
          <div class="calendar-year"
          :class="{
            disabled: isBeforeDate(year, min, 'YYYY') || isAfterDate(year, max, 'YYYY'),
            current: isCurrentYear(year),
          }" @click="!isBeforeDate(year, min, 'YYYY') && !isAfterDate(year, max, 'YYYY') ? selectYear(year) : null">{{year}}</div>
        </div>
      </div>
      <b-row class="calendar-months" v-if="openCalendarMonths">
        <b-col cols="4" v-for="(month, index) in months" :key="`month-${index}`">
          <div class="calendar-month"
          :class="{
            disabled: isBeforeDate(`${selectedYear}-${month}`, min, 'YYYY-MMMM') || isAfterDate(`${selectedYear}-${month}`, max, 'YYYY-MMMM'),
            current: isCurrentMonth(month),
          }" @click="!isBeforeDate(`${selectedYear}-${month}`, min, 'YYYY-MMMM') && !isAfterDate(`${selectedYear}-${month}`, max, 'YYYY-MMMM') ? selectMonth(month) : null">
            {{$t(month) | truncate(0, 3)}}
          </div>
        </b-col>
      </b-row>
      <div class="calendar-week-days" v-if="openCalendarDays">
        <div class="calendar-week-day" v-for="(weekDay, index) in weekDays" :key="`week-day-${index}`">
          {{$t(weekDay) | truncate(0, 3)}}
        </div>
      </div>
      <div class="days" v-if="openCalendarDays">
        <div class="day" :class="{
          disabled: isBeforeDate(day, min) || isAfterDate(day, max),
          current: isToday(day),
          occuped: isOccuped(day),
          selected: day === date,
        }" v-for="(day, index) in monthDays" :key="`month-day-${index}`"
        @click="!isOccuped(day) && !isBeforeDate(day, min) && !isAfterDate(day, max) ? selectDate(day) : null">
          {{getDayNumber(day)}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RFInput from '@/components/forms/RFInput'
import moment from 'moment'

export default {
  name: 'DatePicker',
  props: {
    value: String,
    label: {
      type: String,
      default: 'Date',
    },
    color: {
      type: String,
      default: '#24425B',
    },
    min: {
      type: String,
      default: moment().startOf('year').subtract(100, 'years').format('YYYY-MM-DD'),
    },
    max: {
      type: String,
      default: moment().format('YYYY-MM-DD'),
    },
    occupedDates: Array,
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    RFInput,
  },
  data() {
    return {
      isOpened: false,
      openCalendarYears: false,
      openCalendarMonths: false,
      openCalendarDays: false,

      months: [],
      weekDays: [],
      monthDays: [],

      selectedYear: null,
      selectedMonth: null,

      firstDay: null,

      date: null,

      selectedDecadeIndex: null,
    }
  },
  computed: {
    datePickerStyle() {
      return {
        '--color': this.color,
        '--column': this.firstDay,
      }
    },
    minYear() {
      return this.min && moment(this.min, 'YYYY-MM-DD').format('YYYY')
    },
    minMonth() {
      return this.min && moment(this.min, 'YYYY-MM-DD').format('MM')
    },
    minDay() {
      return this.min && moment(this.min, 'YYYY-MM-DD').format('DD')
    },
    maxYear() {
      return this.max && moment(this.max, 'YYYY-MM-DD').format('YYYY')
    },
    maxMonth() {
      return this.max && moment(this.max, 'YYYY-MM-DD').format('MM')
    },
    maxDay() {
      return this.max && moment(this.max, 'YYYY-MM-DD').format('DD')
    },
    startDecades() {
      const currentYear = moment().format('YYYY')
      const minYear = parseInt(this.minYear) || parseInt(currentYear) - 100
      const maxYear = parseInt(this.maxYear) || parseInt(currentYear) + 100

      let years = []
      for (let i = minYear; i <= maxYear; i += 10) {
        years = [...years, i]
      }

      return years
    },
    decades() {
      return this.startDecades.map((year) => ( { start: year, end: year + 9 } ))
    },
    yearsInDecades() {
      return this.decades.map(decade => {
        let yearsDecade = []

        for (let i = decade.start; i <= decade.end; i++) {
          yearsDecade = [...yearsDecade, i]
        }

        return yearsDecade
      })
    },
    selectedDecade() {
      return this.yearsInDecades[this.selectedDecadeIndex]
    }
  },
  beforeMount() {
    this.date = this.value || null
    this.selectedYear = moment().format('YYYY')

    this.months = moment.months()
    this.weekDays = moment.weekdays()

    const currentYear = parseInt(this.selectedYear)
    this.selectedDecadeIndex = this.decades.findIndex(decade => currentYear >= decade.start && currentYear <= decade.end)
  },
  watch: {
    monthDays: {
      handler() {
        const weekDay = moment(this.monthDays[0], 'YYYY-MM-DD').isoWeekday() + 1 || 1
        this.firstDay = weekDay > 7 ? 1 : weekDay
      },
    },
    selectedYear: {
      handler() {
        this.updateDates()
      }
    },
    selectedMonth: {
      handler() {
        this.updateDates()
      }
    }
  },
  methods: {
    toggleCalendar() {
      this.isOpened = !this.isOpened
    },
    showPrev() {
      if (this.openCalendarYears) {
        return this.selectedDecadeIndex !== 0
      } else if (this.openCalendarMonths) {
        return !this.isBeforeOrSameDate(this.selectedYear, this.minYear, 'YYYY')
      } else if (this.openCalendarDays) {
        return !this.isBeforeOrSameDate(`${this.selectedYear}-${this.selectedMonth}`, `${this.minYear}-${this.minMonth}`, 'YYYY-MM')
      }
    },
    showNext() {
      if (this.openCalendarYears) {
        return this.selectedDecadeIndex < this.decades.length - 1
      } else if (this.openCalendarMonths) {
        return !this.isAfterOrSameDate(this.selectedYear, this.maxYear, 'YYYY')
      } else if (this.openCalendarDays) {
        return !this.isAfterOrSameDate(`${this.selectedYear}-${this.selectedMonth}`, `${this.maxYear}-${this.maxMonth}`, 'YYYY-MM')
      }
    },
    openCalendar() {
      this.toggleCalendar()

      if (this.isOpened) {
        if (!this.selectedMonth && !this.date) {
          this.openCalendarYears = true
        } else {
          this.openCalendarYears = false
          this.selectedYear = this.getYear(this.date)
        }

        this.openCalendarMonths = !!this.selectedMonth && !this.date
        this.openCalendarDays = !!this.date

        if (this.openCalendarDays) {
          this.selectedMonth = this.getMonth(this.date)
        }
      }
    },
    handleOpenCalendar() {
      if (this.openCalendarMonths) {
        this.openCalendarMonths = false
        this.openCalendarYears = true
      } else if (this.openCalendarDays) {
        this.openCalendarDays = false
        this.openCalendarMonths = true
        this.selectedMonth = null
      }
    },
    subYear() {
      if (this.openCalendarYears) {
        if (this.selectedDecadeIndex > 0) {
          this.selectedDecadeIndex -= 1
        }
      } else if (this.openCalendarMonths) {
        this.selectedYear = moment(this.selectedYear, 'YYYY').subtract(1, 'years').format('YYYY')
      } else if (this.openCalendarDays) {
        this.selectedMonth = moment(this.selectedMonth, 'MMMM').subtract(1, 'month').format('MMMM')
      }
    },
    addYear() {
      if (this.openCalendarYears) {
        if (this.selectedDecadeIndex < this.decades.length - 1) {
          this.selectedDecadeIndex += 1
        }
      } else if (this.openCalendarMonths) {
        this.selectedYear = moment(this.selectedYear, 'YYYY').add(1, 'years').format('YYYY')
      } else if (this.openCalendarDays) {
        this.selectedMonth = moment(this.selectedMonth, 'MMMM').add(1, 'month').format('MMMM')
      }
    },
    selectYear(year) {
      this.selectedYear = year
      this.openCalendarYears = false
      this.openCalendarMonths = true
    },
    selectMonth(month) {
      this.selectedMonth = month
      this.openCalendarMonths = false
      this.openCalendarDays = true
      this.updateDates()
    },
    selectDate(date) {
      this.date = date
      this.selectedMonth = null
      this.openCalendarDays = false
      this.toggleCalendar()
      this.$emit('input', this.date)
    },
    updateDates() {
      if (this.selectedMonth) {
        const daysInMonth = moment(`${this.selectedYear}-${this.selectedMonth}`, 'YYYY-MM').daysInMonth()
        this.monthDays = []

        for (let i = 1; i < daysInMonth + 1; i++) {
          const date = moment().year(this.selectedYear).month(this.selectedMonth).date(i).format('YYYY-MM-DD')
          this.monthDays = [...this.monthDays, date]
        }

        this.monthDays = this.monthDays.filter(monthDay => moment(monthDay, 'YYYY-MM-DD').format('MMMM') === this.selectedMonth)
      }
    },
    isToday(date) {
      return date === moment().format('YYYY-MM-DD')
    },
    isCurrentMonth(month) {
      return `${this.selectedYear}-${month}` === moment().format('YYYY-MMMM')
    },
    isCurrentYear(year) {
      return `${year}` === moment().format('YYYY')
    },
    getDayNumber(date) {
      return moment(date, 'YYYY-MM-DD').format('DD')
    },
    getMonth(date) {
      return moment(date, 'YYYY-MM-DD').format('MMMM')
    },
    getYear(date) {
      return moment(date, 'YYYY-MM-DD').format('YYYY')
    },
    isOccuped(date) {
      return this.occupedDates && this.occupedDates.length && this.occupedDates.includes(date)
    },
    isBeforeDate(date, dateToCompare, format = 'YYYY-MM-DD') {
      return dateToCompare ? moment(date.toString()).isBefore(moment(dateToCompare).format(format)) : false
    },
    isAfterDate(date, dateToCompare, format = 'YYYY-MM-DD') {
      return dateToCompare ? moment(date.toString()).isAfter(moment(dateToCompare).format(format)) : false
    },
    isBeforeOrSameDate(date, dateToCompare, format = 'YYYY-MM-DD') {
      return dateToCompare ? moment(date.toString()).isSameOrBefore(moment(dateToCompare).format(format)) : false
    },
    isAfterOrSameDate(date, dateToCompare, format = 'YYYY-MM-DD') {
      return dateToCompare ? moment(date.toString()).isSameOrAfter(moment(dateToCompare).format(format)) : false
    }
  }
}
</script>

<style lang="scss" scoped>
.datepicker {
  position: relative;
  @include font-style($montserrat, 'medium', $font-14);

  .calendar {
    border: 1px solid var(--color);
    position: absolute;
    z-index: 20;
    background-color: $white;
    min-width: 100%;
    max-width: 130%;

    .selected-year {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .year {
        border: 1px solid var(--color);
        cursor: pointer;
        padding: 5px 20px;
        border-radius: 5px;
        background-color: transparent;
        transition: background-color .3s;

        &:hover {
          background-color: var(--color);
          color: $white;
        }
      }
    }
    .selected-month {
      display: flex;
      justify-content: center;
      align-items: center;

      .month {
        cursor: pointer;
        border: 1px solid transparent;
        transition: border .3s;
        padding: 5px 20px;
        border-radius: 5px;

        &:hover {
          border: 1px solid var(--color);
        }
      }
    }
  }
  .calendar-years,
  .calendar-months,
  .calendar-month-days {
    padding: 10px;

    .calendar-year,
    .calendar-month,
    .calendar-month-day {
      text-align: center;
      padding: 10px 0;
      border: 1px solid transparent;
      transition: all .3s;
      border-radius: 5px;
      cursor: pointer;

      &:hover {
        background-color: var(--color);
        color: $white;
      }
      &.current {
        border: 1px solid var(--color);
      }
      &.disabled {
        color: $gray;

        &:hover {
          background-color: transparent;
        }
      }
    }
  }
  .calendar-years {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .calendar-year {
      padding: 10px;
      margin: 0 5px;
    }
  }
  .calendar-week-days,
  .days {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
  }
  .calendar-week-days {
    border-bottom: 1px solid $gray;
    margin-bottom: 5px;
    padding-bottom: 5px;

    .calendar-week-day {
      text-align: center;
    }
  }
  .days {
    .day {
      padding: 10px 0;
      border-radius: 5px;
      text-align: center;
      cursor: pointer;
      border: 1px solid transparent;
      transition: all .3s;

      &:hover {
        background-color: var(--color);
        color: $white;
      }
      &:first-child {
        grid-column: var(--column);
      }
      &.current {
        border: 1px solid var(--color);
        color: var(--color);

        &:hover {
          color: $white;
        }
      }
      &.disabled {
        color: $gray;
      }
      &.occuped {
        color: $danger;
      }
      &.disabled,
      &.occuped {
        &:hover {
          background-color: transparent;
        }
      }
      &.selected {
        background-color: var(--color);
        color: $white;
      }
    }
  }
}
</style>
