import Vue from 'vue'
import { http, httpAdmin, httpAuth } from '@/helpers'
import { authService } from '@/services'

const handleError = (error) => {
  if (error.response.status === 401) {
    Vue.toasted.error('Si è verificato un errore...')
    authService.logout()
    return error.response
  }
  Vue.toasted.error(error.response.data.message)
  return error.response
}

export const getItem = async (endpoint) => {
  try {
    return await http.get(endpoint)
  } catch (error) {
    handleError(error)
  }
}

export const getAuthItem = async (endpoint) => {
  try {
    return await httpAuth.get(endpoint)
  } catch (error) {
    handleError(error)
  }
}

export const getItems = async (endpoint) => {
  try {
    const { data: items } = await http.get(endpoint)
    return items
  } catch (error) {
    handleError(error)
  }
}

export const getAuthItems = async (endpoint) => {
  try {
    const { data: items } = await httpAuth.get(endpoint)
    return items
  } catch (error) {
    handleError(error)
  }
}

export const getAdminItems = async (endpoint) => {
  try {
    const { data: items } = await httpAdmin.get(endpoint)
    return items
  } catch (error) {
    handleError(error)
  }
}

export const postData = async (endpoint, data) => {
  try {
    return await http.post(endpoint, data)
  } catch (error) {
    handleError(error)
  }
}

export const postAuthData = async (endpoint, data = {}) => {
  try {
    return await httpAuth.post(endpoint, data)
  } catch (error) {
    handleError(error)
  }
}

export const postAdminData = async (endpoint, data) => {
  try {
    return await httpAdmin.post(endpoint, data)
  } catch (error) {
    handleError(error)
  }
}

export const putAuthData = async (endpoint, data) => {
  try {
    return await httpAuth.put(endpoint, data)
  } catch (error) {
    handleError(error)
  }
}

export const putAdminData = async (endpoint, data) => {
  try {
    return await httpAdmin.put(endpoint, data)
  } catch (error) {
    return handleError(error)
  }
}

export const patchData = async (endpoint, data) => {
  try {
    return await http.patch(endpoint, data)
  } catch (error) {
    handleError(error)
  }
}

export const patchAuthData = async (endpoint, data) => {
  try {
    return await httpAuth.patch(endpoint, data)
  } catch (error) {
    handleError(error)
  }
}

export const patchAdmin = async (endpoint) => {
  try {
    return await httpAdmin.patch(endpoint)
  } catch (error) {
    handleError(error)
  }
}

export const patchAdminData = async (endpoint, data) => {
  try {
    return await httpAdmin.patch(endpoint, data)
  } catch (error) {
    handleError(error)
  }
}

export const deleteAdminData = async (endpoint) => {
  try {
    return await httpAdmin.delete(endpoint)
  } catch (error) {
    handleError(error)
  }
}
