const state = {
  apartment: null,
}

const mutations = {
  setApartment(state, apartment) {
    state.apartment = apartment
  }
}

export const apartmentStore = {
  namespaced: true,
  state,
  mutations,
}