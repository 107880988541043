import { countryService } from '@/services'

const state = {
  countries: []
}

const mutations = {
  setCountries(state, countries) {
    state.countries = countries
  }
}

const actions = {
  async getCountries({ commit }) {
    const countries = await countryService.getCountries()
    commit('setCountries', countries)
  }
}

export const countryStore = {
  namespaced: true,
  state,
  mutations,
  actions,
}