import { getItems, postAdminData, putAdminData, deleteAdminData, patchAdminData, getAdminItems } from '@/utils/network'

const getAccommodationTypes = async () => await getItems(`/accomodationtypes`)

const addAccommodationType = async accommodationType => await postAdminData(`/accomodationtypes`, accommodationType)

const editAccommodationType = async accommodationType => await putAdminData(`/accomodationtype/${accommodationType.id}`, accommodationType)

const getRoomsAccommodationTypes = async () => await getAdminItems(`/rooms/accomodationtypes`)

const editRoomsAccommodationTypes = async (accommodationType,roomAccommodationTypeRequest) => await putAdminData(`/rooms/accomodationtype/${accommodationType.id}`,roomAccommodationTypeRequest)

// const deleteAccommodationType = async accommodationTypeId => await deleteAdminData(`/accomodation-type/${accommodationTypeId}`)

// const restoreAccommodationType = async accommodationTypeId => await patchAdminData(`/accomodation-type/${accommodationTypeId}`)

export const accommodationTypeService = {
  getAccommodationTypes,
  addAccommodationType,
  editAccommodationType,
  getRoomsAccommodationTypes,
  editRoomsAccommodationTypes
  // deleteAccommodationType,
  // restoreAccommodationType,
}
