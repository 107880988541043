import { getAuthItem, postAuthData } from '@/utils/network'

const getChangeCardConfig = async reservationId => {
  const { data: config } = await getAuthItem(`/changecard/access/${reservationId}`)

  return config
}

const confirmChangeCard = async (reservationId, form) => {
  const { data: response } = await postAuthData(`/changecard/confirm/${reservationId}`, form)

  return response
}

export const changeCardService = {
  getChangeCardConfig,
  confirmChangeCard,
}