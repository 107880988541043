const state = {
  ticket: null,
}

const mutations = {
  setTicket(state, ticket) {
    state.ticket = ticket
  }
}

export const adminTicketStore = {
  namespaced: true,
  state,
  mutations,
}