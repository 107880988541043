const state = {
  width: null,
  height: null,
  isSm: null,
  isMd: null,
  isLg: null,
}

const mutations = {
  setWidth(state, width) {
    state.width = width
    state.isSm = width <= 768
    state.isMd = width > 768 && width < 992
    state.isLg = width >= 992
  },
  setHeight(state, height) {
    state.height = height
  }
}

const getters = {
  width(state) {
    return state.width
  },
  height(state) {
    return state.height
  },
  isSm(state) {
    return state.isSm
  },
  isMd(state) {
    return state.isMd
  },
  isLg(state) {
    return state.isLg
  }
}

export const windowStore = {
  namespaced: true,
  state,
  mutations,
  getters,
}