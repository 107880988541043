import { buildingTypeService } from '@/services/admin'

const state = {
  buildingTypes: [],
  buildingType: null,
}

const mutations = {
  setBuildingTypes(state, buildingTypes) {
    state.buildingTypes = buildingTypes
  },
  setBuildingType(state, buildingType) {
    state.buildingType = buildingType
  }
}

const actions = {
  async getBuildingTypes({ commit }) {
    const buildingTypes = await buildingTypeService.getBuildingTypes()
    commit('setBuildingTypes', buildingTypes)
  }
}

export const adminBuildingTypeStore = {
  namespaced: true,
  state,
  mutations,
  actions,
}