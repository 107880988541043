<template>
  <div class="rf-radio-group" :class="{ 'd-flex': inline, 'd-block': !inline }">
    <div v-if="label" class="rf-radio-label" :class="{ 'rf-radio-label-disabled': disabled }" :style="{ color }">
      {{$t(label)}}
    </div>
    <div class="rf-radio-container" v-for="(item, index) in list" :key="index">
      <input
        class="rf-radio-input"
        type="radio"
        :disabled="disabled"
        :name="name"
        :value="item.value"
        :checked="item.value === value"
        :id="`${name}-${index}`"
        @change="$emit('input', $event.target.value)">
      <label :for="`${name}-${index}`" :style="checkStyle" class="rf-radio" :class="{ checked: item.value === value, disabled }"/>
      <label class="rf-radio-input-label" :for="`${name}-${index}`" :style="{ color: disabled ? '#ccc' : labelColor }">{{$t(item.label)}}</label>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
    },
    label: {
      type: String,
    },
    name: {
      type: String,
    },
    color: {
      type: String,
      default: '#24425B',
    },
    labelColor: {
      type: String,
      default: '#24425B',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    inline: {
      type: Boolean,
      default: true,
    },
    value: {
      type: [String, Boolean],
    }
  },
  computed: {
    checkStyle() {
      return {
        '--color': this.color
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.rf-radio-group {
  display: flex;
  flex-direction: column;
  margin: 10px 0;

  .rf-radio-label {
    margin-right: 40px;
    @include font-style($montserrat-bold, 'bold', $font-14);

    &.rf-radio-label-disabled {
      color: $disabled;
    }
  }
  .rf-radio-container {
    display: flex;
    align-items: center;
    margin: 0 10px;

    .rf-radio-input {
      display: none;
    }
    .rf-radio {
      width: 13px;
      height: 13px;
      transform: translateY(50%);
      margin: 0 10px 12px 0;
      border-radius: 50%;
      position: relative;
      border: 1px solid var(--color);
      background-color: $white;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: background-color .3s;

      &.checked {
        background-color: var(--color);
      }
      &.disabled {
        border: .5px solid $disabled;
        cursor: default;
      }
    }
    .rf-radio-input-label {
      @include font-style($hind, 'medium', $font-14);
      margin: 0;
      white-space: nowrap;
      cursor: pointer;
    }
  }
}

@media screen and (min-width: map-get($grid-breakpoints, 'md')) {
  .rf-radio-group {
    flex-direction: row;
    align-items: center;
  }
}
</style>
