<template>
  <div class="rf-select" :style="selectStyle">
    <div class="rf-select-label" v-if="label">{{label}}</div>
    <div class="rf-select-container">
      <div class="rf-select-default" @click="openOptions">
        <div class="default-label">{{$t(selectedValue) || $t('Select') | replaceUnderscore | capitalize}}</div>
        <FontAwesomeIcon class="ml-2" :icon="['fas', 'caret-down']"/>
      </div>
      <div class="rf-options" v-if="list.length && showOptions">
        <div class="rf-option" v-for="(item, index) in list" :key="index" @click="onSelect(item)">
          {{$t(item.label)}}
        </div>
      </div>
      <div class="top-label" :class="{
      'stay-on-top': list.length,
    }" v-if="topLabel">{{topLabel}}</div>
    </div>
  </div>
</template>

<script>
import { capitalize } from '@/helpers/filters'

export default {
  props: {
    list: {
      type: Array,
      required: true,
    },
    label: {
      type: String,
    },
    background: {
      type: String,
      default: '#fff',
    },
    color: {
      type: String,
      default: '#24425B',
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    topLabel: {
      type: String,
      default: null,
    },
    value: {
      type: [String, Number],
      default: null,
    },
    fullWidth: {
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {
      showOptions: false,
      selectedValue: null,
    }
  },
  computed: {
    selectStyle() {
      return {
        '--background': this.background,
        '--color': this.color,
        '--width': this.fullWidth ? '100%' : '200px',
      }
    }
  },
  beforeMount() {
    if (this.value) {
      this.selectedValue = this.value
    }
  },
  watch: {
    list: {
      handler() {
        if (!this.list.length) {
          this.selectedValue = null
        }
      }
    }
  },
  methods: {
    openOptions() {
      if (!this.readonly) {
        this.showOptions = !this.showOptions
      }
    },
    onSelect(item) {
      this.selectedValue = item.label
      this.$emit('input', item.value)

      if (item.function) {
        this.$emit('function')
      }

      this.showOptions = !this.showOptions
    }
  }
}
</script>

<style lang="scss" scoped>
.rf-select {
  margin-top: 20px;
  width: var(--width);
  display: flex;
  align-items: center;
  height: 32px;

  .rf-select-label {
    @include font-style($montserrat-bold, 'bold', $font-14);
    color: $dark-blue;
    text-transform: uppercase;
    white-space: nowrap;
    margin-right: 10px;
  }
  .rf-select-container {
    position: relative;
    width: var(--width);

    .rf-select-default {
      @include font-style($montserrat, 'medium', $font-14);
      background-color: var(--background);
      border: 1px solid var(--color);
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 5px 15px;
      width: 100%;
      cursor: default;
      overflow-x: hidden;

      .default-label {
        white-space: nowrap;
        width: 90%;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .rf-options {
      @include font-style($montserrat, 'medium', $font-14);
      position: absolute;
      width: 100%;
      background-color: var(--background);
      max-height: calc(35px * 3);
      overflow-y: auto;
      z-index: 20;
      border: 1px solid var(--color);
      border-top: none;

      .rf-option {
        padding: 5px 10px;
        cursor: pointer;

        &:hover {
          background-color: var(--color);
          color: $white;
        }
      }
    }
    .top-label {
      @include font-style($montserrat, 'medium', $font-14);
      position: absolute;
      top: 5px;
      left: 15px;
      transition: all .3s;
      z-index: 10;
      padding: 0 15px 0 2px;
      background-color: $white;

      &.stay-on-top {
        top: -6px;
        padding: 0 2px;
        font-size: $font-10;
      }
    }
  }
}
</style>
