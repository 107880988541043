import moment from 'moment'
import Vue from 'vue'
import i18n from '@/plugins/i18n'

export const formatDate = date => date ? i18n.locale === 'en'
                                    ? moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY')
                                    : moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY')
                                  : null

export const formatDateTime = dateTime => dateTime ? i18n.locale === 'en'
                                            ? moment(dateTime, 'YYYY-MM-DD HH:mm:ss').format('MM/DD/YYYY HH:mm')
                                            : moment(dateTime, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm')
                                          : null

export const splitOnUppercase = (string) => {
  string = `${string.charAt(0).toUpperCase()}${string.slice(1)}`
  return string.match(/[A-Z][a-z]+|[0-9]+/g).join(' ')
}

export const extractYearMonthFromDate = date => date ? moment(date, 'YYYY-MM-DD').format('MMM YYYY') : null

export const extractMonthYearFromDate = date => date ? moment(date, 'YYYY-MM-DD').format('MMMM YYYY') : null


export const extractMonthLocaleFromDate = date =>{
  if(date){
    let dateFormatted = moment(date, 'YYYY-MM-DD').format('MMMM YYYY')
    return i18n.t(dateFormatted.split(' ')[0])
  }
  return null

}

export const extractYearFromDate = date =>{
  if(date){
    let dateFormatted = moment(date, 'YYYY-MM-DD').format('MMMM YYYY')
    return dateFormatted.split(' ')[1]
  }
  return null

}

export const replaceUnderscore = string => string.toLowerCase().split('_').join(' ')

export const getAsset = image =>  `${process.env.VUE_APP_IMAGES_URL}${image}`

export const truncate = (string, to, from, points = false) => string.length > from && points ? `${string.substring(to, from)}...` : string.substring(to, from)

export const capitalize = (string) => {
  string = string ? replaceUnderscore(string) : null
  return string ? `${string.charAt(0).toUpperCase()}${string.slice(1)}` : null
}

export const toUpperCase = string => string.toUpperCase()

export const extractExpiringDate = date => `${date.substring(0, 4)}/${date.substring(4, date.length)}`

export const getPreview = file => file ? URL.createObjectURL(file) : null

const filters = {
  formatDate,
  formatDateTime,
  extractYearMonthFromDate,
  extractMonthYearFromDate,
  extractMonthLocaleFromDate,
  extractYearFromDate,
  replaceUnderscore,
  getAsset,
  truncate,
  capitalize,
  toUpperCase,
  extractExpiringDate,
  splitOnUppercase,
  getPreview,
}

const filterList = [
  { name: 'formatDate', filterFunction: filters.formatDate },
  { name: 'formatDateTime', filterFunction: filters.formatDateTime },
  { name: 'extractYearMonthFromDate', filterFunction: filters.extractYearMonthFromDate },
  { name: 'extractMonthYearFromDate', filterFunction: filters.extractMonthYearFromDate },
  { name: 'replaceUnderscore', filterFunction: filters.replaceUnderscore },
  { name: 'getAsset', filterFunction: filters.getAsset },
  { name: 'truncate', filterFunction: filters.truncate },
  { name: 'capitalize', filterFunction: filters.capitalize },
  { name: 'toUpperCase', filterFunction: filters.toUpperCase },
  { name: 'extractExpiringDate', filterFunction: filters.extractExpiringDate },
  { name: 'splitOnUppercase', filterFunction: filters.splitOnUppercase },
  { name: 'getPreview', filterFunction: filters.getPreview },
  { name: 'extractMonthLocaleFromDate', filterFunction: filters.extractMonthLocaleFromDate },
  { name: 'extractYearFromDate', filterFunction: filters.extractYearFromDate },
]

filterList.forEach((filter) => Vue.filter(filter.name, filter.filterFunction))

export default filters
