import { roles } from '@/enums'

export const preventCutCopy = () => {
  document.body.oncopy = () => false
  document.body.oncut = () => false
  document.body.onpaste = () => false
}

export const resetCutCopy = () => {
  document.body.oncopy = () => true
  document.body.oncut = () => true
  document.body.onpaste = () => true
}

const findIndex = (array, field) => array.findIndex(item => item.field === field)

export const extractValueByField = (array, field) => {
  const index = findIndex(array, field)
  return array[index].value
}

export const replaceFieldValue = (array, field, value) => {
  const index = findIndex(array, field)
  array[index].value = value
}

export const extractIds = list => list.length ? list.map(item => item.id) : []

export const isAdmin = userRoles => userRoles.includes(roles.ADMIN)

export const getMonth = monthNumber => {
  const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ]
  return monthNames[monthNumber]
}
