import Vue from 'vue'
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate'
import { email } from 'vee-validate/dist/rules'
import i18n from '@/plugins/i18n'

const validationComponents = [
  { name: 'ValidationObserver', component: ValidationObserver },
  { name: 'ValidationProvider', component: ValidationProvider },
]

validationComponents.forEach(validationComponent => Vue.component(validationComponent.name, validationComponent.component))

extend('email', {
  ...email,
  message: field => `${i18n.t('Invalid email format')}`
})

extend('required', {
  validate: value => {
    return {
      required: true,
      valid: ['', null, undefined].indexOf(value) === -1,
    }
  },
  message: field => field !== '{field}' ? `${field} ${i18n.t('is required')}` : `${i18n.t('This field is required')}`,
  computesRequired: true,
})

extend('minLength', {
  validate: value => {
    return {
      required: true,
      valid: value.length >= 8
    }
  },
  message: field => `${field} ${i18n.t('must have almost 8 characters')}`,
  computesRequired: true,
})

extend('phoneNumber', {
  validate: value => {
    return {
      required: true,
      valid: validPhoneNumber(value),
    }
  },
  message: field => `${field} ${i18n.t('Must have between 7 and 12 digits')}`,
  computesRequired: true,
})

extend('password', {
  validate: value => {
    return {
      required: true,
      valid: validatePassword(value)
    }
  },
  message: field => `${field} ${i18n.t('must have almost 8 characters, almost one number, lower and upper case characters and special characters.')}`,
  computesRequired: true,
})

extend('fiscalCode', {
  validate: value => {
    return {
      required: true,
      valid: validateFiscalCode(value),
    }
  },
  message: field => i18n.t('Invalid fiscal code format'),
  computesRequired: true,
})

const validatePassword = (password) => {
  const format = /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>?~]/
  const characters = {
    lowerCase: 0,
    upperCase: 0,
    number: 0,
    special: 0,
  }

  for (let char of password) {
    if (characters.lowerCase === 0) {
      if (char === char.toLowerCase() && !isNumber(char)) {
        characters.lowerCase++
      }
    }
    if (characters.upperCase === 0) {
      if (char === char.toUpperCase() && !isNumber(char)) {
        characters.upperCase++
      }
    }
    if (characters.number === 0) {
      if (isNumber(char)) {
        characters.number++
      }
    }
    if (format.test(char)) {
      characters.special++
    }
  }

  let isValid = 0
  Object.values(characters).forEach(value => isValid += value)

  return isValid >= Object.keys(characters).length
}

const isNumber = char => char >= '0' && char <= '9'

const validPhoneNumber = phoneNumber => {
  const regex = /^[0-9]{7,12}$/
  return regex.test(phoneNumber)
}

const validateFiscalCode = fiscalCode => {
  if (fiscalCode) {
    const regex = /^([A-Za-z]{6}[0-9lmnpqrstuvLMNPQRSTUV]{2}[abcdehlmprstABCDEHLMPRST]{1}[0-9lmnpqrstuvLMNPQRSTUV]{2}[A-Za-z]{1}[0-9lmnpqrstuvLMNPQRSTUV]{3}[A-Za-z]{1})$|([0-9]{11})$/
    return regex.test(fiscalCode)
  }

  return true
}

extend('positiveNumber', {
  validate: value => {
    return {
      required: true,
      valid: value>=0,
    }
  },
  message: field => `${field} ${i18n.t('must be a positive number')}`,
  computesRequired: true,
})

extend('max', {
  validate(value,{limit}){
    return {valid: value === null || value === undefined || value.length <= limit};
  },
  params:["limit"],
  message: (fieldName,placeholders) =>{return `${i18n.t('Maximum number of characters is')} ${placeholders.limit}`},
  computesRequired: true,
})

