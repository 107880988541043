import {deleteAdminData, getAdminItems, getItems, patchAdminData, postAdminData, putAdminData} from '@/utils/network'

const getApartments = async () => await getAdminItems(`/apartments`)

const getActiveApartments = async () => await getItems(`/apartments`)

const getActiveApartmentsWithCapacity = async (guests) => await getAdminItems(`/apartments/active/filter?guests=${guests}`)

const addApartment = async apartment => await postAdminData(`/apartments/add`, apartment)

const addResource = async (apartmentId, data) => await postAdminData(`/apartment/${apartmentId}/addresource`, data)

const addInfo = async (apartmentId, infos) => await postAdminData(`/apartment/${apartmentId}/addinfo`, infos)

const editApartment = async apartment => await putAdminData(`/apartment/${apartment.id}`, apartment)

const deleteApartment = async apartmentId => await deleteAdminData(`/apartment/${apartmentId}`)

const restoreApartment = async apartmentId => await patchAdminData(`/apartment/${apartmentId}`)

const getApartmentImages = async apartmentId => await getAdminItems(`/apartment/${apartmentId}/images`)

export const apartmentService = {
  getApartments,
  getActiveApartments,
  getActiveApartmentsWithCapacity,
  addApartment,
  addResource,
  addInfo,
  editApartment,
  deleteApartment,
  restoreApartment,
  getApartmentImages
}
