<template>
  <div class="menu" :style="{ backgroundColor: backgroundMenu }">
    <b-container id="menu-container">
      <b-row id="menu-row" class="justify-content-between">
        <b-col cols="auto">
          <router-link to="/">
            <img src="@/assets/images/logo.png" />
          </router-link>
        </b-col>
        <b-col cols="auto" class="d-flex align-items-center">
          <div class="item" v-for="(item, index) in items" :key="index">
            <template v-if="item.children">
              <div class="menu-item has-submenu">
                {{$t(item.label)}}
                <FontAwesomeIcon color="#fff" :icon="['fas', 'caret-down']"/>
                <ul class="submenu" :style="{ backgroundColor: colorTheme }">
                  <li class="submenu-item" v-for="(child, index) in item.children" :key="index">
                    <router-link class="submenu-item-link" :to="child.to">{{$t(child.label)}}</router-link>
                  </li>
                </ul>
              </div>
            </template>
            <template v-else-if="item.anchor">
              <div class="menu-item cursor-pointer" v-if="$route.name === item.page" @click="scrollToSection(item.anchor)">{{$t(item.label)}}</div>
              <router-link v-else :to="item.to" class="menu-item">{{$t(item.label)}}</router-link>
            </template>
            <template v-else>
              <a :href="item.to" class="menu-item">{{$t(item.label)}}</a>
            </template>
          </div>

          <RFFlag class="mx-3"/>
          <section class="login-section" v-if="!loggedIn">
            <RFButton
              transparent
              background="#67AEA6"
              :border-color="backgroundMenu === 'transparent' ? 'transparent' : '#fff'"
              color="#fff"
              :label="$t('Sign up')"
              @on-click="goToSignUp"/>
            <RFButton
              transparent
              :background="colorTheme"
              :border-color="backgroundMenu === 'transparent' ? 'transparent' : '#fff'"
              color="#fff"
              :label="$t('Login')"
              @on-click="goToSignIn"/>
            <transition name="fade">
              <Login class="modal-login" :theme="colorTheme" v-if="isOpened"/>
            </transition>
          </section>
          <div class="logged-user-container" v-else>
            <div class="logged-user" @click="openUserMenu = !openUserMenu">{{user.firstname}}</div>
            <transition name="fade">
              <div class="user-menu" v-if="openUserMenu">
                <router-link @click.native="openUserMenu = !openUserMenu" class="user-menu-item" :to="isAdmin ? { name: 'admin' } : { name: 'profile' }">{{$t('View Profile')}}</router-link>

                <router-link @click.native="openUserMenu = !openUserMenu" class="user-menu-item" to="/profile/change-password">{{$t('Change password')}}</router-link>

                <div class="user-menu-item cursor-pointer" @click="logout">{{$t('Logout')}}</div>
              </div>
            </transition>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import Login from '@/components/auth/Login'
import RFButton from '@/components/forms/RFButton'
import RFFlag from '@/components/language/RFFlag'

import { items } from '@/json/menu-items.json'
import { roles } from '@/enums'
import { mapState } from 'vuex'
import { authService } from '@/services'
import { isAdmin } from '@/helpers/functions'

export default {
  name: 'Menu',
  components: { Login, RFButton, RFFlag },
  props: {
    theme: String,
    themeColor: String,
  },
  data() {
    return {
      items: items,
      isOpened: false,
      backgroundMenu: null,
      openUserMenu: false,
    }
  },
  computed: {
    ...mapState({
      user: state => state.userStore.user,
      loggedIn: state => state.userStore.loggedIn,
    }),
    colorTheme() {
      return this.themeColor === 'green' ? '#67AEA6'
            : this.themeColor === 'blue' ?  '#24425B'
            : this.themeColor === 'orange' ? '#E26F5A'
            : 'transparent'
    },
    isAdmin() {
      return this.user && isAdmin(this.user.roles)
    }
  },
  mounted() {
    this.setBackground()

    window.addEventListener('scroll', () => {
      const scroll = window.scrollY

      if (this.theme === 'transparent') {
        if (scroll >= (window.innerHeight * 0.1)) {
          this.backgroundMenu = this.colorTheme
        } else {
          this.backgroundMenu = 'transparent'
        }
      }
    })
  },
  methods: {
    scrollToSection(anchor) {
      const section = document.getElementById(anchor)

      window.scroll({
        top: section.offsetTop -86,
        behavior: 'smooth',
      })
    },
    goToSignUp() {
      this.$router.push({ name: 'sign-up' })
    },
    goToSignIn() {
      this.$router.push({ name: 'login' })
    },
    setBackground() {
      this.backgroundMenu = this.theme === 'transparent' ? 'transparent' :
                            this.theme === 'green' ? '#67AEA6' :
                            this.theme === 'blue' ? '#24425B' :
                            this.theme === 'orange' ? '#E26F5A'
                            : '#24425B'
    },
    logout() {
      authService.logout()

      this.isOpened = false

      if (!this.$store.state.userStore.loggedIn) {
        this.$router.push({ name: 'homepage' })
      }
    }
  },
  watch: {
    $route: {
      deep: true,
      handler() {
        this.setBackground()
        this.isOpened = false
        this.openUserMenu = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.menu {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
  padding: 15px 0;
  transition: background-color .3s;

  .item {
    display: flex;
    align-items: center;
    height: 20px;
  }
  .menu-item {
    @include font-style($montserrat-bold, 'bold', $font-14);
    text-transform: uppercase;
    color: $white;
    margin: 0 10px;

    &:hover {
      text-decoration: none;
    }
    &.has-submenu {
      cursor: pointer;
      position: relative;

      .submenu {
        position: absolute;
        margin-top: 10px;
        max-height: 0;
        padding: 0;
        transition: max-height .3s;
        overflow-y: hidden;

        .submenu-item {
          @include font-style($montserrat-bold, 'bold', $font-14);
          list-style: none;
          color: $white;

          &:hover {
            background-color: rgba($white, .2);
          }
          .submenu-item-link {
            display: block;
            padding: 10px 30px;
            text-decoration: none;
            color: $white;
          }
        }
      }
      &:hover {
        .submenu {
          max-height: 100px;
          // padding: 15px;
        }
      }
    }
  }
  .login-section {
    position: relative;

    .modal-login {
      position: absolute;
      top: 60px;
      transform: translateX(-35%);
    }
  }
  .logged-user-container {
    position: relative;

    .logged-user {
      position: relative;
      @include font-style($hind, 'medium', $font-16);
      color: $white;
      text-transform: uppercase;
      cursor: pointer;

      &:hover {
        text-decoration: none;
      }
    }
    .user-menu {
      position: absolute;
      display: flex;
      flex-direction: column;
      width: 180px;
      top: 30px;
      left: -100px;
      border: 1px solid $dark-blue;

      .user-menu-item {
        @include font-style($hind, 'medium', $font-16);
        background-color: $white;
        padding: 5px 10px;
        color: $dark-blue;
        text-transform: uppercase;
        text-decoration: none;
        transition: background-color .3s, color .3s;

        &:hover {
          background-color: $dark-blue;
          color: $white;
        }
      }
    }
  }
}

@media screen and (min-width: map-get($grid-breakpoints, 'md')) and (max-width: map-get($grid-breakpoints, 'lg')) {
  .menu {
    #menu-container{
      #menu-row{
        flex-wrap: nowrap;
      }
    }
  }
}
</style>
