import { getAuthItem, postAuthData } from '@/utils/network'

const getPaymentConfig = async (reservationId) => {
  const { data: payment } = await getAuthItem(`/payments/access/${reservationId}`)

  return payment
}

const confirmPayment = async (form) => {
  const { data: response } = await postAuthData(`/payments/confirm`, form)

  return response
}

const getMonthlyRentPaymentConfig = async (reservationId) => {
  const { data: payment } = await getAuthItem(`/payments/monthlyrent/access/${reservationId}`)

  return payment
}

const confirmMonthlyRentPayment = async (reservationId, form) => {
  const { data: response } = await postAuthData(`/payments/monthlyrent/confirm/${reservationId}`, form)

  return response
}

const confirmMonthlyRentAutomaticPayment = async (reservationId, form) => {
  const { data: response } = await postAuthData(`/payments/monthlyrentAutomatic/confirm/${reservationId}`, form)

  return response
}

const confirmBankTransferPayment = async (reservationId) => {
  const {status} = await postAuthData(`/reservation/${reservationId}/btpayment`)

  return status
}

const confirmPaymentWithBankTransferMonthlyPayments = async (form) => {
  const { data: response } = await postAuthData(`/payments/confirmUnique`, form)

  return response
}

const confirmPaymentWithMonthlyPaymentsManual = async (form) => {
  const { data: response } = await postAuthData(`/payments/confirmManual`, form)

  return response
}

export const paymentService = {
  getPaymentConfig,
  confirmPayment,
  confirmBankTransferPayment,
  getMonthlyRentPaymentConfig,
  confirmMonthlyRentPayment,
  confirmMonthlyRentAutomaticPayment,
  confirmPaymentWithBankTransferMonthlyPayments,
  confirmPaymentWithMonthlyPaymentsManual
}
