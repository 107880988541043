<template>
  <div class="rf-input-container">
    <input
    class="rf-input"
    :type="!!passwordType ? passwordType : type"
    :name="label"
    @input="onInput($event)"
    @click="$emit('click')"
    @focusout="$emit('focusout')"
    @focusin="$emit('focusin')"
    @keydown.down="$emit('key-down')"
    @keydown.up="$emit('key-up')"
    @keydown.enter.prevent="$emit('key-enter')"
    :value="value"
    :min="min"
    :max="max"
    :readonly="readonly"
    :autocomplete="autocomplete"
    :style="{ border: `1px solid ${color}`, color: labelColor, backgroundColor: background }"/>
    <FontAwesomeIcon class="show-password" ref="showPassword" size="sm" :icon="['fas', 'eye']" v-if="passwordType && value" @click="showPassword"/>
    <FontAwesomeIcon class="show-password" ref="showPassword" size="sm" :icon="['fas', 'eye-slash']" v-if="type === 'password' && passwordType === 'text' && value" @click="showPassword"/>
    <label class="rf-input-label"
    :style="{ color: labelColor }"
    :class="{
      'stay-on-top': isValid,
    }">{{$t(label)}}</label>
    <p class="rf-input-description" v-if="description">{{$t(description)}}</p>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      default: 'text',
    },
    required: {
      type: Boolean,
      default: false,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
    },
    color: {
      type: String,
      default: '#24425B',
    },
    labelColor: {
      type: String,
      default: '#000',
    },
    value: {
      default: null,
    },
    background: {
      default: null,
      type: String,
    },
    min: {
      type: Number,
      default: null,
    },
    max: {
      type: Number,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    autocomplete: {
      type: String,
      default: 'off',
    },
    isNumeric: {
      type: Boolean,
      default: false,
    },
    uppercase: {
      type: Boolean,
      default: false,
    },
    description: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      passwordType: null,
    }
  },
  computed: {
    isValid() {
      return this.value !== '' && this.value !== null && this.value !== undefined
    }
  },
  created() {
    if (this.type === 'password') {
      this.passwordType = this.type
    }
  },
  methods: {
    onInput(event) {
      if (this.isNumeric) {
        event.target.value = event.target.value.replace(/[^0-9]/g, '')

        if (event.target.value) {
          const number = parseInt(event.target.value)
          this.$emit('input', number)
          return true
        } else {
          return false
        }
      }

      if (this.uppercase) {
        this.$emit('input', event.target.value.toUpperCase())
        return true
      } else {
        this.$emit('input', event.target.value)
      }

    },
    showPassword() {
      this.passwordType = this.passwordType
                          ? this.passwordType === 'password' ? 'text' : 'password'
                          : null
    }
  }
}
</script>

<style lang="scss" scoped>
.rf-input-container {
  position: relative;
  margin: 20px 0 0 0;
  width: 100%;

  .rf-input {
    @include font-style($montserrat, 'medium', $font-14);
    position: relative;
    padding: 5px 15px;
    background: transparent;
    z-index: 1;
    width: 100%;
    height: 32px;

    &:focus  {
      outline: none;
    }
    &:focus + .rf-input-label {
      top: -7.5px;
      font-size: $font-10;
      z-index: 5;
    }
  }
  .show-password {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
    cursor: pointer;
    z-index: 10;
  }
  .rf-input-label {
    @include font-style($montserrat, 'medium', $font-14);
    position: absolute;
    top: 7px;
    left: 15px;
    transition: all .3s;
    padding: 0 2px;
    z-index: 0;
    background-color: $white;
    margin-bottom: 0;

    &.stay-on-top {
      top: -7.5px;
      font-size: $font-10;
      z-index: 5;
    }
  }
  .rf-input-description {
    @include font-style($montserrat-light, 'light', $font-10);
    color: var(--color);
    line-height: .8rem;
    padding: 0 15px;
    margin-top: 3px;
  }
}
</style>