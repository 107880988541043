<template>
  <ValidationProvider :rules="rules" :name="name" :mode="mode" v-slot="{ errors }">
    <slot></slot>
    <transition name="fade">
      <div class="vee-error" v-if="errors[0]">{{errors[0]}}</div>
    </transition>
  </ValidationProvider>
</template>

<script>
export default {
  name: 'RFValidation',
  props: {
    name: String,
    rules: String,
    mode: {
      type: String,
      default: 'eager',
    }
  }
}
</script>

<style>

</style>