import { deleteAdminData, patchAdmin, postAdminData, getAdminItems, putAdminData } from '@/utils/network'
import { paymentStatus } from '@/enums'

const getReservationList = async (status, accommodationType) => {
  let endpoint = '/reservations/filter'
  endpoint = status ? `${endpoint}?status=${status}` : endpoint
  endpoint = accommodationType ? `${endpoint}${status ? '&' : '?'}resourceType=${accommodationType}` : endpoint

  return await getAdminItems(endpoint)
}

const getBuildingRoomsByTypes = async (buildingId, type) => await getAdminItems(`/building/${buildingId}/rooms?typeId=${type}`)

const getBuildingOccupiedRooms = async (buildingId, startDate) => await getAdminItems(`/reservations/buildings/${buildingId}/rooms/filter?month=${startDate}`)

const getOccupiedRooms = async (startDate) => await getAdminItems(`/reservations/rooms/filter?month=${startDate}`)

const getOccupiedApartments = async (startDate) => await getAdminItems(`/reservations/apartments/filter?month=${startDate}`)

const getUserToVerifyList = async () => await getAdminItems(`/userstoverify`)

const updateReservation = async (reservationId, status) => await patchAdmin(`/reservation/${reservationId}/updatestatus?newStatus=${status}`)

const getPaymentList = async () => await getAdminItems(`/payments`)

const getAllPaymentList = async () => await getAdminItems(`/user-payments`)

const getReservationFromPayment = async (id) => await getAdminItems(`/payment-reservation/${id}`)

const getBankTransferList = async () => await getAdminItems(`/payments?type=BT&status=${paymentStatus.PENDING}`)

const confirmBankTransferPaymentFromAdmin = async (reservationId) => {
  const { status } = await postAdminData(`/reservation/${reservationId}/btpayment`)

  return status
}

const confirmPayment = async (paymentId, form) => await postAdminData(`/payments/confirm/${paymentId}`, form)

const acceptRoomReservation = async (reservationId, roomId) => await patchAdmin(`/reservation/${reservationId}/room/${roomId}`)

const updateUserStatus = async (userId, status) => {
  const { data: updatedUser } = await patchAdmin(`/users/${userId}/updatestatus?newStatus=${status}`)

  return updatedUser
}

const addAdmin = async admin => await postAdminData(`/admin/add`, admin)

const deleteInfo = async id => await deleteAdminData(`/info/${id}`)

const deleteImage = async id => await deleteAdminData(`/media/${id}`)

const editImage = async id => await putAdminData(`/media/${id}`)

const updateCalendarSlot = async updatedSlot => await putAdminData(`/calendar`,updatedSlot)

export const adminService = {
  getReservationList,
  getBuildingRoomsByTypes,
  getBuildingOccupiedRooms,
  getOccupiedRooms,
  getOccupiedApartments,
  getUserToVerifyList,
  getPaymentList,
  getBankTransferList,
  getAllPaymentList,
  getReservationFromPayment,

  acceptRoomReservation,
  updateReservation,
  updateUserStatus,

  confirmBankTransferPaymentFromAdmin,
  confirmPayment,

  addAdmin,

  deleteInfo,
  deleteImage,
  editImage,

  updateCalendarSlot
}
