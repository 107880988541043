import { accommodations } from '@/enums'

const state = {
  item: null,
  type: null,
  buildingId: null,
  checkIn: null,
  checkOut: null,
  guests: null,
}

const mutations = {
  setReservation(state, reservation) {
    state.item = reservation.item
    state.type = reservation.type
    state.checkIn = reservation.type === accommodations.APARTMENT ? reservation.checkIn : null
    state.checkOut = reservation.type === accommodations.APARTMENT ? reservation.checkOut : null
    state.guests = reservation.type === accommodations.APARTMENT ? reservation.guests : null
    state.buildingId = reservation.type === accommodations.ROOM ? reservation.buildingId : null
  },
}

export const reservationStore = {
  namespaced: true,
  state,
  mutations,
}
