<template>
  <div class="hamburger-container" @click="activeHamburger" :class="{ 'active': isActive }">
    <div class="rf-hamburger" :style="hamburgerStyle" />
  </div>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '#fff',
    },
    toggle: {
      type: Boolean,
    }
  },
  data() {
    return {
      isActive: false,
    }
  },
  computed: {
    hamburgerStyle() {
      return {
        '--color': this.color,
      }
    },
  },
  methods: {
    activeHamburger() {
      this.isActive = !this.isActive
      this.$emit('update', this.isActive)
    }
  },
  watch: {
    toggle() {
      this.isActive = this.toggle
    }
  }
}
</script>

<style lang="scss" scoped>
.hamburger-container {
  width: 30px;
  height: 30px;
  position: relative;
  cursor: pointer;

  .rf-hamburger {
    width: inherit;
    height: inherit;

    &:before,
    &:after {
      content: '';
      width: inherit;
      height: 3px;
      background-color: var(--color);
      transition: transform .3s;
    }

    &:before {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
    &:after {
      position: absolute;
      bottom: 0;
      transform: translateY(-50%);
    }
  }

  &.active {
    .rf-hamburger {
      &:before {
        top: 50%;
        transform: rotate(45deg);
      }
      &:after {
        top: 50%;
        transform: rotate(-45deg);
      }
    }
  }
}
</style>