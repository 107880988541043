import Vue from 'vue'
import Vuex from 'vuex'

import { windowStore } from './window.store'
import { apartmentStore } from './apartment.store'
import { buildingStore } from './building.store'
import { roomStore } from './room.store'
import { reservationStore } from './reservation.store'
import { userStore } from '@/stores/user.store'
import { searchStore } from '@/stores/search.store'
import { profileStore } from '@/stores/profile.store'
import { countryStore } from '@/stores/country.store'
import { contractStore } from '@/stores/contract.store'
import { documentStore } from '@/stores/document.store'
import { carouselStore } from '@/stores/carousel.store'
import { accommodationTypeStore } from '@/stores/accommodation-type.store'
import { categoryStore } from '@/stores/category.store'

import { adminStore } from '@/stores/admin/admin.store'
import { adminApartmentStore } from '@/stores/admin/apartment.store'
import { adminBuildingStore } from '@/stores/admin/building.store'
import { adminRoomStore } from '@/stores/admin/room.store'
import { adminAmenityStore } from '@/stores/admin/amenity.store'
import { adminHouseRuleStore } from '@/stores/admin/house-rule.store'
import { adminPaymentStore } from '@/stores/admin/payment.store'
import { adminCharacteristicStore } from '@/stores/admin/building-characteristic.store'
import { adminOwnerStore } from '@/stores/admin/owner.store'
import { adminTicketStore } from '@/stores/admin/ticket.store'
import { adminUserStore } from '@/stores/admin/user.store'
import { adminBuildingTypeStore } from '@/stores/admin/building-type.store'
import { adminServiceStore } from '@/stores/admin/service.store'
import { adminServiceTypeStore } from '@/stores/admin/service-type.store'
import { adminReservationStore } from '@/stores/admin/reservation.store'

import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export const store = new Vuex.Store({
  modules: {
    carouselStore,
    windowStore,
    apartmentStore,
    buildingStore,
    roomStore,
    reservationStore,
    userStore,
    searchStore,
    profileStore,
    countryStore,
    contractStore,
    documentStore,
    accommodationTypeStore,
    categoryStore,

    adminStore,
    adminApartmentStore,
    adminBuildingStore,
    adminRoomStore,
    adminAmenityStore,
    adminHouseRuleStore,
    adminPaymentStore,
    adminCharacteristicStore,
    adminOwnerStore,
    adminTicketStore,
    adminUserStore,
    adminBuildingTypeStore,
    adminServiceStore,
    adminServiceTypeStore,
    adminReservationStore,
  },
  plugins: [
    createPersistedState({
      storage: window.sessionStorage,
    })
  ],
})
