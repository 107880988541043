import { getAuthItems, postAuthData } from '@/utils/network'

const getTickets = async (reservationId) => await getAuthItems(`/reservation/${reservationId}/tickets`)

const createTicket = async (ticket, reservationId) => await postAuthData(`/reservation/${reservationId}/tickets`, ticket)

const addMessage = async (reservationId, ticketId, message) => await postAuthData(`/reservation/${reservationId}/ticket/${ticketId}`, message)

export const ticketService = {
  getTickets,
  createTicket,
  addMessage,
}