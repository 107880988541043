import { getAdminItems, putAdminData } from '@/utils/network'

const getUsers = async () => await getAdminItems(`/users`)

const updateUser = async user => await putAdminData(`/updateuser/${user.id}`, user)

export const userService = {
  getUsers,
  updateUser,
}
