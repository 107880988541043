<template>
  <div class="rf-flag">
    <div title="it" @click="isOpened = !isOpened" class="flag-icon" :class="$i18n.flag ? `flag-icon-${$i18n.flag}` : 'flag-icon-us'"></div>
    <transition name="fade">
      <div class="languages" v-if="isOpened">
        <div class="language" @click="changeLocale('it')"><flag :squared="false" iso="it" class="mr-2"/>{{$t('Italian')}}</div>
        <div class="language" @click="changeLocale('en')"><flag :squared="false" iso="us" class="mr-2"/>{{$t('English')}}</div>
        <div class="language" @click="changeLocale('es')"><flag :squared="false" iso="es" class="mr-2"/>{{$t('Spanish')}}</div>
      </div>
    </transition>
  </div>
</template>

<script>
import { http, httpAuth } from '@/helpers'

export default {
  data() {
    return {
      isOpened: false,
    }
  },
  methods: {
    changeLocale(locale) {
      this.$i18n.locale = locale
      this.$i18n.flag = locale

      if (this.$i18n.locale === 'en') {
        this.$i18n.flag = 'us'
      }

      http.defaults.headers['lang'] = this.$i18n.locale
      httpAuth.defaults.headers['lang'] = this.$i18n.locale

      this.isOpened = false
    }
  }
}
</script>

<style lang="scss" scoped>
.rf-flag {
  position: relative;

  .flag-icon  {
    cursor: pointer;
  }
  .languages {
    position: absolute;
    top: 55px;
    width: 150px;
    background-color: #fff;
    border: 1px solid #000;
    cursor: pointer;

    .language {
      @include font-style($montserrat, 'medium', $font-14);
      padding: 5px 10px;

      &:hover {
        background-color: $dark-blue;
        color: $white;
      }
    }
  }
}
</style>