import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'
import adminRoutes from './admin-routes'

Vue.use(VueRouter)

const linkActiveClass = 'active-class'

export const router = new VueRouter({
  mode: 'history',
  routes: [
    ...adminRoutes,
    ...routes,
  ],
  linkActiveClass,
  scrollBehavior: () => ({ x: 0, y: 0 })
})