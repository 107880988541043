import { ownerService } from '@/services/admin'

const state = {
  owner: null,
  owners: [],
}

const mutations = {
  setOwner(state, owner) {
    state.owner = owner
  },
  setOwners(state, owners) {
    state.owners = owners
  }
}

const actions = {
  async getOwners({ commit }) {
    const owners = await ownerService.getOwners()
    commit('setOwners', owners)
  }
}

export const adminOwnerStore = {
  namespaced: true,
  state,
  mutations,
  actions,
}