const state = {
  user: null,
  document: {
    front: null,
    back: null,
    full: null,
  },
  filterData: null
}

const mutations = {
  setUser(state, user) {
    state.user = user
  },
  setDocument(state, document) {
    state.document = document
  },
  setFilterData(state, filterData){
    state.filterData = filterData
  }
}

export const adminUserStore = {
  namespaced: true,
  state,
  mutations,
}
