const state = {
  document: null,
}

const mutations = {
  setDocument(state, document) {
    state.document = document
  }
}

export const documentStore = {
  namespaced: true,
  state,
  mutations,
}