<template>
  <button class="rf-button mx-1"
          :style="buttonStyles"
          :type="type"
          :disabled="readonly"
          @click="!readonly ? $emit('on-click') : null">
    <FontAwesomeIcon class="rf-button-icon" v-if="icon" :icon="icon" :size="iconSize"/>
    {{$t(label)}}
  </button>
</template>

<script>
export default {
  props: {
    type: {
      default: 'submit',
    },
    label: {
      default: 'Button',
    },
    background: {
      default: '#24425B',
    },
    color: {
      default: '#fff',
    },
    borderColor: {
      default: null,
    },
    rounded: {
      default: true,
      type: Boolean,
    },
    upperCase: {
      default: true,
      type: Boolean,
    },
    size: {
      default: 'medium',
    },
    iconSize: {
      default: 'lg',
    },
    fullWidth: {
      default: false,
      type: Boolean,
    },
    borderSize: {
      type: String,
      default: '1.5',
    },
    fontWeight: {
      type: String,
      default: 'medium',
    },
    icon: {
      type: Array,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    padding() {
      return this.size === 'small' ? '5px 10px'
              : this.size === 'medium' ? '7px 16px'
              : this.size === 'large' ? '10px 20px'
              : null
    },
    fontSize() {
      return this.size === 'small' ? '10px'
              : this.size === 'medium' ? '12px'
              : this.size === 'large' ? '16px'
              : null
    },
    borderRadius() {
      return this.rounded ? this.size === 'small' || this.size === 'medium' ? '20px' : '25px' : 'none'
    },
    uppercase() {
      return this.upperCase ? 'uppercase' : 'none'
    },
    border() {
      return !this.borderColor ? `${this.borderSize}px solid ${this.color}` : `${this.borderSize}px solid ${this.borderColor}`
    },
    backgroundHover() {
      return this.color
    },
    colorHover() {
      return this.background
    },
    borderHover() {
      return `${this.borderSize}px solid ${this.colorHover}`
    },
    width() {
      return this.fullWidth ? '100%' : 'auto'
    },
    buttonStyles() {
      return {
        'width': this.width,
        'border-radius': this.borderRadius,
        'padding': this.padding,
        'text-transform': this.uppercase,
        'font-size': this.fontSize,
        '--font-weight': this.fontWeight,
        '--background': this.background,
        '--color': this.color,
        '--border': this.border,
        '--background-hover': this.backgroundHover,
        '--color-hover': this.colorHover,
        '--border-hover': this.borderHover,
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.rf-button[disabled] {
  pointer-events: none;
}

.rf-button {
  background: var(--background);
  width: 100%;
  letter-spacing: 1px;
  cursor: pointer;
  transition: all .3s;
  color: var(--color);
  border: var(--border);
  font-family: $montserrat;
  font-weight: var(--font-weight);
  white-space: nowrap;

  .rf-button-icon {
    color: var(--color);
  }

  &:hover {
    background: var(--background-hover);
    color: var(--color-hover);
    border: var(--border-hover);

    .rf-button-icon {
      color: var(--color-hover);
    }
  }
  &:focus {
    outline: none;
  }
}
</style>
