import moment from 'moment'

const state = {
  searchParams: {
    startDate: null,
    endDate: null,
    category: null,
    price: null,
  }
}

const mutations = {
  setParams(state, searchParams) {
    if (searchParams) {
      searchParams.endDate = moment(searchParams.startDate).add(searchParams.months, 'month').format('YYYY-MM-DD')
      state.searchParams = { ...searchParams }
    } else {
      Object.keys(state.searchParams).forEach(field => state.searchParams[field] = null)
    }
  }
}

export const searchStore = {
  namespaced: true,
  state,
  mutations,
}
