const state = {
  reservation: null,
  user: null,
}

const mutations = {
  setReservation(state, reservation) {
    state.reservation = reservation
  },
  setUser(state, user) {
    state.user = user
  },
  reset(state) {
    Object.keys(state).forEach(key => state[key] = null)
  }
}

export const adminStore = {
  namespaced: true,
  state,
  mutations,
}