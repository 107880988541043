import { getAdminItems, postAdminData, putAdminData } from '@/utils/network'

const getTickets = async () => await getAdminItems(`/tickets`)

const editTicket = async ticket => await putAdminData(`/ticket/${ticket.id}`, ticket)

const addMessage = async (ticketId, message) => await postAdminData(`/ticket/${ticketId}/addmessage`, message)

export const ticketService = {
  getTickets,
  editTicket,
  addMessage,
}