import axios from 'axios'
import accessToken from '@/helpers/access-token'

export const httpAdmin = axios.create({
  baseURL: process.env.VUE_APP_BASE_ADMIN_URL,
  headers: {
    'Content-type': 'application/json',
    'Accept': 'application/json',
    'X-Auth': accessToken,
  }
})