import { store } from '@/stores'

const handleViewport = () => {
  store.commit('windowStore/setWidth', window.innerWidth)
  store.commit('windowStore/setHeight', window.innerHeight)
}

handleViewport()

window.addEventListener('resize', handleViewport)
