import { getItems, getItem } from '@/utils/network'

const getApartment = async id => await getItem(`/apartment/${id}`)

const getApartmentByName = async name => await getItem(`/apartment/${name}`)

const getApartments = async () => await getItems(`/apartments`)

const getFilteredApartments = async (startDate, endDate) => {
  let endpoint = `/apartments/search`
  if(startDate !== null || endDate !== null){
    endpoint = `${endpoint}?`
  }
  endpoint = startDate ? `${endpoint}startDate=${startDate}` : endpoint
  if(startDate!== null && endDate !== null){
    endpoint=`${endpoint}&`
  }
  endpoint = endDate ? `${endpoint}endDate=${endDate}` : endpoint


  return await getItems(endpoint)
}

export const apartmentService = {
  getApartment,
  getApartments,
  getApartmentByName,
  getFilteredApartments
}
