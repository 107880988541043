<template>
  <div class="menu-mobile" :style="{ backgroundColor: isOpened ? colorTheme : backgroundMenu }">
    <b-container>
      <b-row class="justify-content-between align-items-center py-3">
        <b-col cols="auto">
          <router-link to="/">
            <img src="@/assets/images/logo.png">
          </router-link>
        </b-col>
        <b-col cols="auto">
          <Hamburger :toggle="isOpened" @update="toggleMenu($event)"/>
        </b-col>
      </b-row>
      <b-row class="menu-mobile-content" :class="{ 'opened': isOpened }">
        <b-col cols="12">
          <div v-for="(item, index) in items" :key="index" class="my-3">
            <span class="menu-item" v-if="item.children && item.children.length" @click="openSubmenu(index)">
              {{$t(item.label)}} <FontAwesomeIcon :icon="['fas', 'caret-down']"/>
            </span>
            <a :href="item.to" class="menu-item-link" v-else>{{$t(item.label)}}</a>
            <ul class="submenu" :class="{ 'active': currentSubmenu === index }">
              <li class="menu-item" v-for="(child, index) in item.children" :key="index">
                <router-link class="menu-item-link" @click.native="toggleMenu(false)" :to="child.to">{{$t(child.label)}}</router-link>
              </li>
            </ul>
          </div>
          <section class="mt-4" v-if="!loggedIn">
            <RFButton background="#fff" :border-color="colorTheme" :color="colorTheme" label="Login" @on-click="isOpenedLogin = !isOpenedLogin"/>
            <transition name="fade">
              <Login class="modal-login mt-3" :theme="colorTheme" v-if="isOpenedLogin"/>
            </transition>
          </section>
          <section class="mt-3" v-else>
            <div class="rf-white-color text-uppercase">
              {{user.firstname}}
              <FontAwesomeIcon :icon="['fas', 'caret-down']" @click="openUserMenu = !openUserMenu"/>
            </div>
            <transition name="fade">
              <div class="user-menu" v-if="openUserMenu">
                <router-link class="user-menu-item" to="/profile">{{$t('View Profile')}}</router-link>

                <router-link class="user-menu-item" to="/profile/change-password">{{$t('Change password')}}</router-link>

                <div class="user-menu-item cursor-pointer" @click="logout">{{$t('Logout')}}</div>
              </div>
            </transition>
          </section>
          <RFFlag class="mt-3"/>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import RFFlag from '@/components/language/RFFlag'
import RFButton from '@/components/forms/RFButton'
import Hamburger from '@/components/menu/Hamburger'
import Login from '@/components/auth/Login'
import { authService } from '@/services'
import { items } from '@/json/menu-items.json'

export default {
  components: { Hamburger, RFFlag, RFButton, Login },
  props: {
    theme: String,
    themeColor: String,
  },
  data() {
    return {
      items: items,
      currentSubmenu: null,
      isOpened: false,
      isOpenedLogin: false,
      backgroundMenu: null,
      openUserMenu: false,
    }
  },
  computed: {
    user() {
      return this.$store.state.userStore.user
    },
    loggedIn() {
      return this.$store.state.userStore.loggedIn
    },
    colorTheme() {
      return this.themeColor === 'green' ? '#67AEA6'
            : this.themeColor === 'blue' ?  '#24425B'
            : this.themeColor === 'orange' ? '#E26F5A'
            : 'transparent'
    },
    burgerStyle() {
      return {
        '--layer-bg-color': 'white',
      }
    }
  },
  mounted() {
    this.backgroundMenu = this.theme === 'transparent' ? 'transparent' :
                          this.theme === 'green' ? '#67AEA6' :
                          this.theme === 'blue' ? '#24425B' :
                          this.theme === 'orange' ? '#E26F5A'
                          : '#24425B'

    window.addEventListener('scroll', () => {
      const scroll = window.scrollY

      if (this.theme === 'transparent') {
        if (scroll >= 1) {
          this.backgroundMenu = this.colorTheme
        } else {
          this.backgroundMenu = 'transparent'
        }
      }

    })
  },
  methods: {
    setBackground() {
      this.backgroundMenu = this.theme === 'transparent' ? 'transparent' :
                            this.theme === 'green' ? '#67AEA6' :
                            this.theme === 'blue' ? '#24425B' :
                            this.theme === 'orange' ? '#E26F5A'
                            : '#24425B'
    },
    openSubmenu(index) {
      if (this.currentSubmenu === index) {
        this.currentSubmenu = null
      } else {
        this.currentSubmenu = index
      }
    },
    toggleMenu(toggle = false) {
      this.isOpened = toggle

      if (this.isOpened) {
        document.querySelector('body').classList.add('overflow-hidden')
      } else {
        document.querySelector('body').classList.remove('overflow-hidden')
      }
    },
    logout() {
      authService.logout()
      this.isOpened = false

      if (!this.$store.state.userStore.loggedIn) {
        this.$router.push({ name: 'homepage' })
      }
    }
  },
  watch: {
    $route: {
      deep: true,
      handler() {
        this.setBackground()
        this.isOpened = false
        document.querySelector('body').classList.remove('overflow-hidden')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.menu-mobile {
  position: fixed;
  width: 100%;
  z-index: 50;
  transition: background-color .3s;

  .menu-item,
  .menu-item-link {
    @include font-style($montserrat, 'medium', $font-16);
    color: $white;
    text-transform: uppercase;
    text-decoration: none;
    margin-top: 10px;
  }
  .submenu {
    list-style: none;
    max-height: 0;
    overflow: hidden;
    margin: 0;
    transition: max-height .3s ease-in-out;

    .menu-item {
        margin: 5px 0;

      .menu-item-link {
        @include font-style($montserrat-light, 'light', $font-16);
      }
    }
    &.active {
      max-height: 1000px;
    }
  }
  .menu-mobile-content {
    height: 0;
    overflow-y: hidden;
    transition: height .3s ease-in-out;
    position: relative;
    z-index: 50;

    &.opened {
      height: 100vh;
    }
  }
  .user-menu {
    display: flex;
    flex-direction: column;

    .user-menu-item {
      @include font-style($hind, 'medium', $font-16);
      padding: 5px 10px;
      color: $white;
      text-transform: uppercase;
      text-decoration: none;
      transition: background-color .3s, color .3s;
    }
  }
}
</style>
