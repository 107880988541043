const state = {
  user: null,
  loggedIn: false,
  Facebook: undefined,
};

const mutations = {
  setUser: (state, user) => {
    state.user = user;
    state.loggedIn = !!user;
  },
  updateGuest(state, reservation) {
    const userReservations = state.user.reservations;
    const index = userReservations.findIndex(
      (userReservation) => userReservation.id === reservation.reservationId
    );

    if (index >= 0) {
      const guestIndex = userReservations[index].guests.findIndex(
        (guest) => guest.id === reservation.updatedGuest.id
      );
      state.user.reservations[index].guests[guestIndex] =
        reservation.updatedGuest;
    }
  },
  updateReservationList(state, reservations) {
    state.user.reservations = reservations;
  },
  setFacebook(state, payload) {
    state.Facebook = payload.FB;
  },
};

export const userStore = {
  namespaced: true,
  state,
  mutations,
};
