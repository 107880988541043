import axios from 'axios'
import accessToken from '@/helpers/access-token'
import i18n from '@/plugins/i18n'

export const httpAuth = axios.create({
  baseURL: process.env.VUE_APP_BASE_AUTH_URL,
  headers: {
    'Content-type': 'application/json',
    'Accept': 'application/json',
    'X-Auth': accessToken,
    'lang': i18n.locale,
  }
})