import { postAdminData, getAdminItems, putAdminData, deleteAdminData } from '@/utils/network'

const getAllAmenities = async () => await getAdminItems(`/amenity`)

const addAmenity = async amenityForm => await postAdminData(`/amenity`, amenityForm)

const editAmenity = async amenityForm => await putAdminData(`/amenity/${amenityForm.get('id')}`, amenityForm)

const deleteAmenity = async amenityId => await deleteAdminData(`/amenity/${amenityId}`)

export const amenityService = {
  getAllAmenities,
  addAmenity,
  editAmenity,
  deleteAmenity,
}
