import { serviceTypeService } from '@/services/admin'

const state = {
  serviceTypes: [],
}

const mutations = {
  setServiceTypes(state, serviceTypes) {
    state.serviceTypes = serviceTypes
  }
}

const actions = {
  async getServiceTypes({ commit }) {
    const serviceTypes = await serviceTypeService.getServiceTypes()
    commit('setServiceTypes', serviceTypes)
  }
}

export const adminServiceTypeStore = {
  namespaced: true,
  state,
  mutations,
  actions,
}