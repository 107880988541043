import { getAuthItem } from '@/utils/network'

const getRoofindersInfos = async () => {
  const { data: rfBankInfos } = await getAuthItem(`/owners/roofinders`)

  return rfBankInfos
}

export const ownerService = {
  getRoofindersInfos,
}