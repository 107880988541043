const state = {
  room: null,
}

const mutations = {
  setRoom(state, room) {
    state.room = room
  }
}

export const roomStore = {
  namespaced: true,
  state,
  mutations,
}
