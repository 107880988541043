import { getItems } from '@/utils/network'

const search = async (startDate, endDate, categoryId, price) => {
  let endpoint = `/search/resource/filtered?startDate=${startDate}&endDate=${endDate}`
  endpoint = categoryId ? `${endpoint}&categoryId=${categoryId}` : endpoint
  endpoint = price ? `${endpoint}&price=${price}` : endpoint

  return await getItems(endpoint)
}

export const searchService = {
  search,
}
