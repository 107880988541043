const state = {
  room: null
}

const mutations = {
  setRoom(state, room) {
    state.room = room
  },
  setImages(state, images) {
    state.room.images = images
  }
}

export const adminRoomStore = {
  namespaced: true,
  state,
  mutations,
}