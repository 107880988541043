import { postAuthData } from '@/utils/network'

const sendDocument = async (form, userId) => await postAuthData(`/user/${userId}/adddocument`, form)

const sendGuestDocument = async (form, reservationId, guestId) => await postAuthData(`/reservation/${reservationId}/guest/${guestId}/adddocument`, form)

const acceptContract = async (reservationId, userId) => {
  const { data: reservation } = await postAuthData(`/reservation/${reservationId}/user/${userId}/acceptcontract`)

  return reservation
}

export const documentService = {
  sendDocument,
  sendGuestDocument,
  acceptContract,
}
