const AdminPage = () => import('@/pages/admin')
const AdminLogin = () => import('@/pages/admin/login')

const AdminAmenities = () => import('@/pages/admin/amenities')
const AdminAddAmenity = () => import('@/pages/admin/amenities/add')
const AdminEditAmenity = () => import('@/pages/admin/amenities/edit')
const AdminAllAmenities = () => import('@/pages/admin/amenities/list')

const AdminBuildingCharacteristics = () => import('@/pages/admin/building-characteristics')
const AdminAllBuildingCharacteristics = () => import('@/pages/admin/building-characteristics/list')
const AdminAddBuildingCharacteristic = () => import('@/pages/admin/building-characteristics/add')
const AdminEditBuildingCharacteristic = () => import('@/pages/admin/building-characteristics/edit')

const AdminEditContract = () => import('@/pages/admin/contract')

const AdminReservations = () => import('@/pages/admin/reservations')
const AdminRoomReservations = () => import('@/pages/admin/reservations/rooms')
const AdminApartmentReservations = () => import('@/pages/admin/reservations/apartments')
const AdminRoomReservationsToAccept = () => import('@/pages/admin/reservations/rooms-to-accept')
const AdminApartmentReservationsToAccept = () => import('@/pages/admin/reservations/apartments-to-accept')
const AdminEditReservation = () => import('@/pages/admin/reservations/edit')

const AdminBuildings = () => import('@/pages/admin/buildings')
const AdminAddBuilding = () => import('@/pages/admin/buildings/add')
const AdminAllBuildings = () => import('@/pages/admin/buildings/list')
const AdminEditBuilding = () => import('@/pages/admin/buildings/edit')

const AdminApartments = () => import('@/pages/admin/apartments')
const AdminAddApartment = () => import('@/pages/admin/apartments/add')
const AdminAllApartments = () => import('@/pages/admin/apartments/list')
const AdminEditApartment = () => import('@/pages/admin/apartments/edit')
const AdminPricingApartment = () => import('@/pages/admin/apartments/pricing')

const AdminRooms = () => import('@/pages/admin/rooms')
const AdminAllRooms = () => import('@/pages/admin/rooms/list')
const AdminAddRoom = () => import('@/pages/admin/rooms/add')
const AdminEditRoom = () => import('@/pages/admin/rooms/edit')
const AdminPricingRoom = () => import('@/pages/admin/rooms/pricing')

const AdminUsers = () => import('@/pages/admin/users')
const AdminAllUsers = () => import('@/pages/admin/users/list')
const AdminEditUser = () => import('@/pages/admin/users/edit')
const AdminUsersToVerify = () => import('@/pages/admin/users/to-verify')

const AdminAdd = () => import('@/pages/admin/add')

const AdminHouseRules = () => import('@/pages/admin/house-rules')
const AdminAddHouseRule = () => import('@/pages/admin/house-rules/add')
const AdminHouseRuleList = () => import('@/pages/admin/house-rules/list')

const AdminHeaders = () => import('@/pages/admin/headers')

const AdminContractTemplates = () => import('@/pages/admin/contract-templates')

const AdminPayments = () => import('@/pages/admin/payments')
const AdminPaymentList = () => import('@/pages/admin/payments/list')
const AdminBankTransferList = () => import('@/pages/admin/payments/bank-transfer-list')

const AdminAccommodationTypes = () => import('@/pages/admin/accommodation-types')
const AdminAllAccommodationTypes = () => import('@/pages/admin/accommodation-types/list')
const AdminAddAccommodationType = () => import('@/pages/admin/accommodation-types/add')
const AdminEditAccommodationType = () => import('@/pages/admin/accommodation-types/edit')

const AdminBuildingTypes = () => import('@/pages/admin/building-types')
const AdminAllBuildingTypes = () => import('@/pages/admin/building-types/list')
const AdminAddBuildingType = () => import('@/pages/admin/building-types/add')
const AdminEditBuildingType = () => import('@/pages/admin/building-types/edit')

const AdminOwners = () => import('@/pages/admin/owners')
const AdminAllOwners = () => import('@/pages/admin/owners/list')
const AdminAddOwner = () => import('@/pages/admin/owners/add')
const AdminEditOwner = () => import('@/pages/admin/owners/edit')

const AdminServiceTypes = () => import('@/pages/admin/service-types')
const AdminAllServiceTypes = () => import('@/pages/admin/service-types/list')
const AdminAddServiceType = () => import('@/pages/admin/service-types/add')

const AdminServices = () => import('@/pages/admin/services')
const AdminAllServices = () => import('@/pages/admin/services/list')
const AdminAddService = () => import('@/pages/admin/services/add')
const AdminEditService = () => import('@/pages/admin/services/edit')

const AdminTickets = () => import('@/pages/admin/tickets')
const AdminAllTickets = () => import('@/pages/admin/tickets/list')
const AdminEditTicket = () => import('@/pages/admin/tickets/edit')

const adminRoutes = [
  {
    path: '/administration',
    name: 'admin',
    component: AdminPage,
    meta: {
      noFooter: true,
      noMenu: true,
      theme: 'blue',
      layout: 'blue',
    },
    children: [
      {
        path: 'login',
        name: 'admin-login',
        component: AdminLogin,
        meta: { noFooter: true, noMenu: true },
      },
      {
        path: 'service-types',
        name: 'service-types',
        component: AdminServiceTypes,
        meta: { noFooter: true, noMenu: true },
        children: [
          {
            path: 'list',
            name: 'service-type-list',
            component: AdminAllServiceTypes,
            meta: { noFooter: true, noMenu: true }
          },
          {
            path: 'add',
            name: 'service-type-add',
            component: AdminAddServiceType,
            meta: { noFooter: true, noMenu: true }
          },
        ]
      },
      {
        path: 'services',
        name: 'services',
        component: AdminServices,
        meta: { noFooter: true, noMenu: true },
        children: [
          {
            path: 'list',
            name: 'service-list',
            component: AdminAllServices,
            meta: { noFooter: true, noMenu: true },
          },
          {
            path: 'add',
            name: 'service-add',
            component: AdminAddService,
            meta: { noFooter: true, noMenu: true },
          },
          {
            path: 'edit',
            name: 'service-edit',
            component: AdminEditService,
            meta: { noFooter: true, noMenu: true },
          }
        ]
      },
      {
        path: 'amenities',
        name: 'amenities',
        component: AdminAmenities,
        meta: { noFooter: true, noMenu: true },
        children: [
          {
            path: 'list',
            name: 'amenity-list',
            component: AdminAllAmenities,
            meta: { noFooter: true, noMenu: true },
          },
          {
            path: 'add',
            name: 'amenity-add',
            component: AdminAddAmenity,
            meta: { noFooter: true, noMenu: true },
          },
          {
            path: 'edit',
            name: 'amenity-edit',
            component: AdminEditAmenity,
            meta: { noFooter: true, noMenu: true },
          },
        ]
      },
      {
        path: 'add',
        name: 'admin-add',
        component: AdminAdd,
        meta: { noFooter: true, noMenu: true },
      },
      {
        path: 'building-characteristics',
        name: 'building-characteristics',
        component: AdminBuildingCharacteristics,
        meta: { noFooter: true, noMenu: true },
        children: [
          {
            path: 'list',
            name: 'building-characteristic-list',
            component: AdminAllBuildingCharacteristics,
            meta: { noFooter: true, noMenu: true },
          },
          {
            path: 'add',
            name: 'building-characteristic-add',
            component: AdminAddBuildingCharacteristic,
            meta: { noFooter: true, noMenu: true },
          },
          {
            path: 'edit',
            name: 'building-characteristic-edit',
            component: AdminEditBuildingCharacteristic,
            meta: { noFooter: true, noMenu: true },
          },
        ]
      },
      {
        path: 'contract',
        name: 'contract',
        component: AdminEditContract,
        meta: { noFooter: true, noMenu: true },
      },
      {
        path: 'reservations',
        name: 'reservations',
        component: AdminReservations,
        meta: { noFooter: true, noMenu: true },
        children: [
          {
            path: 'rooms',
            name: 'room-reservation',
            component: AdminRoomReservations,
            meta: { noFooter: true, noMenu: true },
          },
          {
            path: 'apartments',
            name: 'apartment-reservations',
            component: AdminApartmentReservations,
            meta: { noFooter: true, noMenu: true },
          },
          {
            path: 'rooms-to-accept',
            name: 'room-reservations-to-accept',
            component: AdminRoomReservationsToAccept,
            meta: { noFooter: true, noMenu: true },
          },
          {
            path: 'apartments-to-accept',
            name: 'apartment-reservations-to-accept',
            component: AdminApartmentReservationsToAccept,
            meta: { noFooter: true, noMenu: true },
          },
          {
            path: 'edit',
            name: 'edit-reservation',
            component: AdminEditReservation,
            meta: { noFooter: true, noMenu: true },
          }
        ]
      },
      {
        path: 'users',
        name: 'users',
        component: AdminUsers,
        children: [
          {
            path: 'list',
            name: 'user-list',
            component: AdminAllUsers,
            meta: { noFooter: true, noMenu: true },
          },
          {
            path: 'edit',
            name: 'user-edit',
            component: AdminEditUser,
            meta: { noFooter: true, noMenu: true },
          },
          {
            path: 'to-verify',
            name: 'to-verify',
            component: AdminUsersToVerify,
            meta: { noFooter: true, noMenu: true },
          }
        ],
        meta: { noFooter: true, noMenu: true },
      },
      {
        path: 'buildings',
        name: 'buildings',
        component: AdminBuildings,
        meta: { noFooter: true, noMenu: true },
        children: [
          {
            path: 'add',
            name: 'building-add',
            component: AdminAddBuilding,
            meta: { noFooter: true, noMenu: true },
          },
          {
            path: 'list',
            name: 'building-list',
            component: AdminAllBuildings,
            meta: { noFooter: true, noMenu: true },
          },
          {
            path: 'edit',
            name: 'building-edit',
            component: AdminEditBuilding,
            meta: { noFooter: true, noMenu: true },
          }
        ]
      },
      {
        path: 'apartments',
        name: 'apartments',
        component: AdminApartments,
        meta: { noFooter: true, noMenu: true },
        children: [
          {
            path: 'add',
            name: 'apartment-add',
            component: AdminAddApartment,
            meta: { noFooter: true, noMenu: true },
          },
          {
            path: 'list',
            name: 'apartment-list',
            component: AdminAllApartments,
            meta: { noFooter: true, noMenu: true },
          },
          {
            path: 'edit',
            name: 'apartment-edit',
            component: AdminEditApartment,
            meta: { noFooter: true, noMenu: true },
          },
          {
            path: 'pricing',
            name: 'apartment-pricing',
            component: AdminPricingApartment,
            meta: { noFooter: true, noMenu: true },
          }
        ]
      },
      {
        path: 'rooms',
        name: 'rooms',
        component: AdminRooms,
        meta: { noFooter: true, noMenu: true },
        children: [
          {
            path: 'list',
            name: 'room-list',
            component: AdminAllRooms,
            meta: { noFooter: true, noMenu: true },
          },
          {
            path: 'add',
            name: 'room-add',
            component: AdminAddRoom,
            meta: { noFooter: true, noMenu: true },
          },
          {
            path: 'edit',
            name: 'room-edit',
            component: AdminEditRoom,
            meta: { noFooter: true, noMenu: true },
          },
          {
            path: 'pricing',
            name: 'room-pricing',
            component: AdminPricingRoom,
            meta: { noFooter: true, noMenu: true },
          }
        ]
      },
      {
        path: 'house-rules',
        name: 'house-rules',
        component: AdminHouseRules,
        meta: { noFooter: true, noMenu: true },
        children: [
          {
            path: 'add',
            name: 'house-rules-add',
            component: AdminAddHouseRule,
            meta: { noFooter: true, noMenu: true },
          },
          {
            path: 'list',
            name: 'house-rule-list',
            component: AdminHouseRuleList,
            meta: { noFooter: true, noMenu: true },
          },
        ]
      },
      {
        path: 'headers',
        name: 'headers',
        component: AdminHeaders,
        meta: { noFooter: true, noMenu: true },
      },
      {
        path: 'contract-templates',
        name: 'contract-templates',
        component: AdminContractTemplates,
        meta: { noFooter: true, noMenu: true },
      },
      {
        path: 'tickets',
        name: 'tickets',
        component: AdminTickets,
        meta: { noFooter: true, noMenu: true },
        children: [
          {
            path: 'list',
            name: 'ticket-list',
            component: AdminAllTickets,
            meta: { noFooter: true, noMenu: true },
          },
          {
            path: 'edit',
            name: 'edit-ticket',
            component: AdminEditTicket,
            meta: { noFooter: true, noMenu: true },
          }
        ]
      },
      {
        path: 'payments',
        name: 'payments',
        component: AdminPayments,
        meta: { noFooter: true, noMenu: true },
        children: [
          {
            path: 'list',
            name: 'payment-list',
            component: AdminPaymentList,
            meta: { noFooter: true, noMenu: true },
          },
          {
            path: 'bank-transfer-list',
            name: 'bank-transfer-list',
            component: AdminBankTransferList,
            meta: { noFooter: true, noMenu: true },
          }
        ]
      },
      {
        path: 'accommodation-types',
        name: 'accommodation-types',
        component: AdminAccommodationTypes,
        meta: { noFooter: true, noMenu: true },
        children: [
          {
            path: 'list',
            component: AdminAllAccommodationTypes,
            name: 'accommodation-type-list',
            edit: AdminAllAccommodationTypes,
            meta: { noFooter: true, noMenu: true },
          },
          {
            path: 'add',
            name: 'accommodation-type-add',
            component: AdminAddAccommodationType,
            meta: { noFooter: true, noMenu: true },
          },
          {
            path: 'edit',
            name: 'accommodation-type-edit',
            component: AdminEditAccommodationType,
            meta: { noFooter: true, noMenu: true },
          }
        ]
      },
      {
        path: 'building-types',
        name: 'building-types',
        component: AdminBuildingTypes,
        meta: { noFooter: true, noMenu: true },
        children: [
          {
            path: 'list',
            name: 'building-type-list',
            component: AdminAllBuildingTypes,
            meta: { noFooter: true, noMenu: true },
          },
          {
            path: 'add',
            name: 'building-type-add',
            component: AdminAddBuildingType,
            meta: { noFooter: true, noMenu: true },
          },
          {
            path: 'edit',
            name: 'building-type-edit',
            component: AdminEditBuildingType,
            meta: { noFooter: true, noMenu: true },
          }
        ]
      },
      {
        path: 'owners',
        name: 'owners',
        component: AdminOwners,
        meta: { noFooter: true, noMenu: true },
        children: [
          {
            path: 'list',
            name: 'owner-list',
            component: AdminAllOwners,
            meta: { noFooter: true, noMenu: true },
          },
          {
            path: 'add',
            name: 'owner-add',
            component: AdminAddOwner,
            meta: { noFooter: true, noMenu: true },
          },
          {
            path: 'edit',
            name: 'owner-edit',
            component: AdminEditOwner,
            meta: { noFooter: true, noMenu: true },
          }
        ]
      }
    ]
  },
]

export default adminRoutes
