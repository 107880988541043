<template>
  <div class="login-modal" @keyup.enter.prevent="login">
    <RFForm class="mb-3" :fields="fields" :loading="loading" @submit="login"/>
    <router-link class="forgot-password" to="/auth/forgot-password/send-email">{{$t('Forgot password?')}}</router-link>
  </div>
</template>

<script>
import RFInput from '@/components/forms/RFInput'
import RFButton from '@/components/forms/RFButton'
import RFForm from '@/components/forms/RFForm'
import RFLoader from '@/components/forms/RFLoader'
import loginFields from '@/json/forms/login.json'
import { extractValueByField, replaceFieldValue } from '@/helpers'
import { isAdmin } from '@/helpers/functions'
import { authService } from '@/services'
import i18n from "@/plugins/i18n";

export default {
  components: {
    RFInput,
    RFButton,
    RFForm,
    RFLoader,
  },
  props: {
    theme: String
  },
  data() {
    return {
      fields: loginFields,
      form: {
        email: null,
        password: null,
      },
      loading: false,
    }
  },
  destroyed() {
    replaceFieldValue(this.fields, 'email', null)
    replaceFieldValue(this.fields, 'password', null)
  },
  methods: {
    async login() {
      this.loading = true

      this.form.email = i18n.t(extractValueByField(this.fields, 'email'))
      this.form.password = i18n.t(extractValueByField(this.fields, 'password'))

      const loggedUser = await authService.login(this.form)

      this.loading = false

      if (this.$store.state.userStore.loggedIn) {
        replaceFieldValue(this.fields, 'email', null)
        replaceFieldValue(this.fields, 'password', null)

        if (isAdmin(loggedUser.roles)) {
          this.$router.push({ name: 'admin' })
        } else {
          this.$router.push({ name: 'profile' })
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.login-modal {
  background-color: $white;
  padding: 10px 20px 20px 20px;
  width: 300px;
  border: 1px solid $black;

  .forgot-password {
    @include font-style($montserrat, 'semibold', $font-14);
    color: $dark-blue;
  }
}
</style>
