const state = {
  building: null,
}

const mutations = {
  setBuilding(state, building) {
    state.building = building
  },
  setImages(state, images) {
    state.building.images = images
  },
  setInfos(state, infos) {
    state.building.infos = infos
  }
}

export const adminBuildingStore = {
  namespaced: true,
  state,
  mutations,
}