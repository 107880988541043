import { amenityService } from '@/services/admin'

const state = {
  amenity: null,
  amenities: [],
}

const mutations = {
  setAmenity(state, amenity) {
    state.amenity = amenity
  },
  setAmenities(state, amenities) {
    state.amenities = amenities
  },
}

const actions = {
  async getAmenities({ commit }) {
    const amenities = await amenityService.getAllAmenities()
    commit('setAmenities', amenities)
    return amenities
  },
}

export const adminAmenityStore = {
  namespaced: true,
  state,
  mutations,
  actions,
}
