<template>
  <div class="rf-loader" :style="loaderStyle"/>
</template>

<script>
export default {
  name: 'RFLoader',
  props: {
    color: {
      type: String,
      default: '#E26F5A',
    },
    size: {
      type: Number,
      default: 50,
    },
    borderColor: {
      type: String,
      default: '#DADBD7',
    }
  },
  computed: {
    loaderStyle() {
      return {
        'width': `${this.size}px`,
        'height': `${this.size}px`,
        '--color': this.color,
        '--border-color': this.borderColor,
        '--border-size': this.size < 40 ? `${this.size * 0.15}px` : `${this.size * 0.1}px`,
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.rf-loader {
  border: var(--border-size) solid var(--border-color);
  border-top: var(--border-size) solid var(--color);
  border-radius: 50%;
  animation: load 2s linear infinite;
}

@keyframes load {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>