<template>
  <div class="rf-textarea-container">
    <textarea class="rf-textarea" :value="value" @input="$emit('input', $event.target.value)"
    :style="textareaStyle" :readonly="readonly"/>
    <label class="rf-textarea-label" :class="{ 'stay-on-top': isValid }">{{$t(label)}}</label>
  </div>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '#24425B',
    },
    label: {
      type: String,
    },
    value: {
      type: String,
    },
    height: {
      type: Number,
      default: 80,
    },
    readonly: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    textareaStyle() {
      return {
        '--color': this.color,
        'border': `1px solid ${this.color || '#aaa'}`,
        'height': `${this.height}px`
      }
    },
    isValid() {
      return this.value !== '' && this.value !== null && this.value !== undefined
    }
  }
}
</script>

<style lang="scss" scoped>
.rf-textarea-container {
  position: relative;

  .rf-textarea {
    @include font-style($montserrat, 'medium', $font-14);
    width: 100%;
    resize: none;
    padding: 5px 15px;
    background-color: transparent;
    position: relative;
    z-index: 1;
    border: 1px solid var(--color);

    &:focus {
      outline: none;

      + .rf-textarea-label {
        top: -6px;
        font-size: $font-10;
      }
    }
  }
  .rf-textarea-label {
    @include font-style($montserrat, 'medium', $font-14);
    position: absolute;
    top: 10px;
    left: 10px;
    font-size: $font-14;
    transition: all .3s;
    z-index: 10;
    padding: 0 2px;
    background-color: $white;

    &.stay-on-top {
      top: -6px;
      font-size: $font-10;
    }
  }
}
</style>