import { buildingCharacteristicService } from '@/services/admin'

const state = {
  characteristic: null,
  characteristics: [],
}

const mutations = {
  setCharacteristic(state, characteristic) {
    state.characteristic = characteristic
  },
  setCharacteristics(state, characteristics) {
    state.characteristics = characteristics
  }
}

const actions = {
  async getCharacteristics({ commit }) {
    const characteristics = await buildingCharacteristicService.getBuildingCharacteristics()
    commit('setCharacteristics', characteristics)
    return characteristics
  }
}

export const adminCharacteristicStore = {
  namespaced: true,
  state,
  mutations,
  actions,
}
