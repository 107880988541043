const state = {
  reservation: null,
  filterData: null
}

const mutations = {
  setReservation(state, reservation) {
    state.reservation = reservation
  },
  setFilterData(state, filterData){
    state.filterData = filterData
  }
}

export const adminReservationStore = {
  namespaced: true,
  state,
  mutations,
}
