import { getItems, postAdminData, putAdminData } from '@/utils/network'

const getServices = async () => await getItems(`/services`)

const addService = async service => await postAdminData(`/services`, service)

const addIcon = async (serviceId, icon) => await postAdminData(`/services/${serviceId}/addicon`, icon)

const addImages = async (serviceId, images) => await postAdminData(`/services/${serviceId}/addimages`, images)

const updateService = async service => await putAdminData(`/services/${service.id}`, service)

export const additionalService = {
  getServices,
  addService,
  addIcon,
  addImages,
  updateService,
}