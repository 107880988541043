const state = {
  building: null,
}

const mutations = {
  setBuilding(state, building) {
    state.building = building
  }
}

export const buildingStore = {
  namespaced: true,
  state,
  mutations,
}
