import { deleteAdminData, postAdminData, getAdminItems } from '@/utils/network'

const getAllRules = async () => await getAdminItems(`/houserule`)

const addHouseRule = async houseRule => await postAdminData(`/houserule`, houseRule)

const deleteHouseRule = async houseRule => await deleteAdminData(`/houserule/${houseRule.id}`)

export const houseRuleService = {
  getAllRules,
  addHouseRule,
  deleteHouseRule,
}
