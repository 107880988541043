import { postData } from '@/utils/network'

const roomBookingRequest = async (form, buildingId) => await postData(`/reservation/building/${buildingId}/add`, form)

const apartmentBookingRequest = async (form, apartment) => await postData(`/reservation/apartment/${apartment.id}/add`, form)

export const guestService = {
  roomBookingRequest,
  apartmentBookingRequest,
}
