import Vue from 'vue'
import VueI18n from 'vue-i18n'
import enTranslation from '@/json/translations/en.json'
import itTranslation from '@/json/translations/it.json'
import esTranslation from '@/json/translations/es.json'


Vue.use(VueI18n)

const translations = {}

translations.en = enTranslation
translations.it = itTranslation
translations.es = esTranslation

const messages = translations

const i18n = new VueI18n({
  locale: 'en',
  fallbackLocale: 'it',
  silentTranslationWarn: true,
  silentFallbackWarn: true,
  messages,
})

export default i18n

