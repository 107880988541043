import { houseRuleService } from '@/services/admin'

const state = {
  houseRules: [],
}

const mutations = {
  setHouseRules(state, houseRules) {
    state.houseRules = houseRules
  },
}

const actions = {
  async getHouseRules({ commit }) {
    const houseRules = await houseRuleService.getAllRules()
    commit('setHouseRules', houseRules)
  },
}

export const adminHouseRuleStore = {
  namespaced: true,
  state,
  mutations,
  actions,
}
