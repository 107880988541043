const state = {
  indexSlide: null,
  imagesList: null,
  openModal: false,
}

const mutations = {
  setIndexSlide(state, index) {
    state.indexSlide = index
  },
  setImagesList(state, list) {
    state.imagesList = list
  },
  setOpenModal(state, open) {
    state.openModal = open
  },
}

export const carouselStore = {
  namespaced: true,
  state,
  mutations,
}