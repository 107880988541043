const state = {
  payment: null,
  paymentsFilterData: null,
  bankTransferFilterData: null
}

const mutations = {
  setPayment(state, payment) {
    state.payment = payment
  },
  setPaymentsFilterData(state, paymentsFilterData){
    state.paymentsFilterData = paymentsFilterData
  },
  setBankTransferFilterData(state, bankTransferFilterData){
    state.bankTransferFilterData = bankTransferFilterData
  }
}

export const adminPaymentStore = {
  namespaced: true,
  state,
  mutations,
}
