import {deleteAdminData, getAdminItems, patchAdminData, postAdminData, putAdminData} from '@/utils/network'

const getBuildings = async () => await getAdminItems(`/buildings`)

const addBuilding = async building => await postAdminData(`/buildings/add`, building)

const addResource = async (buildingId, data) => await postAdminData(`/buildings/${buildingId}/addresource`, data)

const addInfo = async (buildingId, infos) => await postAdminData(`/buildings/${buildingId}/addinfo`, infos)

const editBuilding = async building => await putAdminData(`/building/${building.id}`, building)

const deleteBuilding = async buildingId => await deleteAdminData(`/building/${buildingId}`)

const restoreBuilding = async buildingId => await patchAdminData(`/building/${buildingId}`)

const getAllRoomsOfBuilding = async buildingId => await getAdminItems(`/building/${buildingId}/allrooms`)

const getActiveRoomsWithCapacity = async(guests,isShared) => await getAdminItems(`/rooms/active/filter?guests=${guests}&isShared=${isShared}`)

const getBuildingRoomTypeImages = async (buildingId, typeId)=> await getAdminItems(`/building/${buildingId}/images/${typeId}`)

export const buildingService = {
  getBuildings,
  addBuilding,
  addResource,
  addInfo,
  editBuilding,
  deleteBuilding,
  restoreBuilding,
  getAllRoomsOfBuilding,
  getActiveRoomsWithCapacity,
  getBuildingRoomTypeImages
}
